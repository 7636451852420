import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ErrorIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32px'} height={'32px'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<rect x={'0'} y={'0'} width={'32'} height={'32'}></rect>
				<path
					d={
						'M16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 16,2.66666667 Z M16,17.3333333 C15.2666667,17.3333333 14.6666667,16.7333333 14.6666667,16 L14.6666667,10.6666667 C14.6666667,9.93333333 15.2666667,9.33333333 16,9.33333333 C16.7333333,9.33333333 17.3333333,9.93333333 17.3333333,10.6666667 L17.3333333,16 C17.3333333,16.7333333 16.7333333,17.3333333 16,17.3333333 Z M17.3333333,22.6666667 L14.6666667,22.6666667 L14.6666667,20 L17.3333333,20 L17.3333333,22.6666667 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
