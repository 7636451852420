import { WithStyles, withStyles } from '@material-ui/core';
import { Ref, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Effect } from '../../utils/function.utils';
import { isEmpty } from '../../utils/string.utils';
import { Info } from '../info/info.component';
import { Input } from '../input/input.component';
import { ResponseWrapper } from '../response-wrapper/response-wrapper.component';
import { inputWithValidationStyles } from './input-with-validation.styles';

const DEFAULT_TEXT_LENGTH = 25;

interface InputValidationResultSuccess {
	isValid: true;
}

interface InputValidationResultError {
	isValid: false;
	error: string;
	displayWhileTyping: boolean;
}

export type InputValidationResult = InputValidationResultSuccess | InputValidationResultError;
interface InputWithValidationTestingLables {
	root: string;
	input: string;
	error: string;
}
export interface InputWithValidationProps {
	type: string;
	label?: string;
	placeholder: string;
	textLength?: number;
	shouldUseDirty?: boolean;
	className?: string;
	onSubmit: Effect<string>;
	validation(value: string, t: TFunction<'translation'>): InputValidationResult;
	onValidationSuccess(value: string): string;
	onFocusChange?: Effect<boolean>;
	testingLabels?: Partial<InputWithValidationTestingLables>;
	inputRef?: Ref<HTMLInputElement>;
	endAdornment?: React.ReactNode;
}

type InputWithValidationComponentProps = WithStyles<typeof inputWithValidationStyles> & InputWithValidationProps;

export const InputWithValidation = withStyles(inputWithValidationStyles)(({
	className,
	classes,
	type,
	label,
	placeholder,
	shouldUseDirty = false,
	textLength = DEFAULT_TEXT_LENGTH,
	testingLabels,
	inputRef,
	endAdornment,
	onSubmit,
	onFocusChange,
	validation,
	onValidationSuccess,
}: InputWithValidationComponentProps) => {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState('');
	const [isDirty, setIsDirty] = useState(false);
	const [validationState, setValidationState] = useState<InputValidationResult>({ isValid: true });

	const isDirtyFlag = useMemo(() => (shouldUseDirty ? isDirty : true), [shouldUseDirty, isDirty]);
	const onValueChange = (value: string) => {
		// eslint-disable-next-line no-control-regex
		const plainText = value.replace(/[^\x00-\x7F]/g, '');
		const validationResult = validation(plainText, t);
		setValidationState(validationResult);
		setInputValue(validationResult.isValid ? onValidationSuccess(plainText) : plainText);
	};

	const renderValidationMessage = (validationState: InputValidationResult) => {
		const errorMessage =
			!validationState.isValid && validationState.displayWhileTyping && isDirtyFlag ? validationState.error : '';
		return (
			errorMessage && (
				<Info
					status={'Alert'}
					label={errorMessage}
					isSimple
					className={classes.errorMessage}
					dataTestingLabel={testingLabels?.error}
				/>
			)
		);
	};

	const handleBlur = (value: string) => {
		!isDirty && value && setIsDirty(true);
		onValueChange(value);
		onFocusChange && onFocusChange(false);
	};

	const handleFocus = () => {
		onFocusChange && onFocusChange(true);
	};
	return (
		<ResponseWrapper
			isDisabled={(!validationState?.isValid && isDirtyFlag) || isEmpty(inputValue)}
			buttonLabel={t('continue', 'Continue')}
			onSubmit={() => validationState.isValid && onSubmit(inputValue)}
			className={className}
			dataTestingLabel={testingLabels?.root}>
			<div className={classes.wrapper}>
				<Input
					inputRef={inputRef}
					onBlur={(e) => handleBlur(e.currentTarget.value)}
					onFocus={handleFocus}
					value={inputValue}
					error={!validationState?.isValid && validationState.displayWhileTyping}
					onChange={(e) => onValueChange(e.currentTarget.value)}
					type={type}
					label={label}
					placeholder={placeholder}
					textLength={textLength}
					endAdornment={endAdornment}
					className={classes.input}
					inputProps={{
						'data-testing-label': testingLabels?.input,
					}}
				/>
			</div>
			{renderValidationMessage(validationState)}
		</ResponseWrapper>
	);
});
