import { ColorVariables } from '../models/app.model';

// brandColor and primaryColor are created manually and stored in db
type HSLAPropertyList = 'hue' | 'saturation' | 'lightness' | 'alpha';

const EXTRACT_HSLA_VALUES_REGEX = /[hsla() ]/g;
const COLOR_VALUE_MARKER = '_x_';
const HSLA_BASE_STRING = `hsla(${COLOR_VALUE_MARKER})`;

const getSetOfHSLAValues = (color: string): string[] => color.replace(EXTRACT_HSLA_VALUES_REGEX, '').split(',');

const createHSLAValue = (values: string[]): string => {
	const valuesString = values.join(', ');
	return HSLA_BASE_STRING.replace(COLOR_VALUE_MARKER, valuesString);
};

export const gedHSLAIndexByProperty = (property: HSLAPropertyList): number => {
	switch (property) {
		case 'hue':
			return 0;
		case 'saturation':
			return 1;
		case 'lightness':
			return 2;
		case 'alpha':
			return 3;
	}
};

const updateHSLAValue = (property: HSLAPropertyList) => (color: string, value: string) => {
	const updateValueIndex = gedHSLAIndexByProperty(property);
	const colorValues = getSetOfHSLAValues(color);
	colorValues[updateValueIndex] = value;

	return createHSLAValue(colorValues);
};

const setSaturation = updateHSLAValue('saturation');
const setLightness = updateHSLAValue('lightness');
export const setAlpha = updateHSLAValue('alpha');

/**
 * Receive primary and brand colors, generates colors for app branding
 * format required: hsla(h, s%, l%, a)
 * Just for testing
 * @param primaryColor
 */

export const generatedColorsKeys = [
	'colorPrimarySoft',
	'colorHead',
	'colorText',
	'colorMediumShadow',
	'colorLightShadow',
	'colorDarkShadow',
	'colorBorder',
	'colorCaption',
	'colorNote',
	'colorInactive',
];

export const staticColorsKeys = [
	'colorPrimary',
	'brandColor',
	'colorHeadOnDark',
	'colorTextOnDark',
	'colorBgBody',
	'colorBgOverlay',
	'colorBgSurface',
];

export const systemColorsKeys = [
	// system colors
	// Default
	'colorSysTextDefault',
	'colorSysDefault',
	'colorSysBgDefault',
	// Alert
	'colorSysTextAlert',
	'colorSysAlert',
	'colorSysBgAlert',
	// Warning
	'colorSysTextWarning',
	'colorSysWarning',
	'colorSysBgWarning',
	// Success
	'colorSysTextSuccess',
	'colorSysSuccess',
	'colorSysBgSuccess',
	// Info
	'colorSysTextInfo',
	'colorSysInfo',
	'colorSysBgInfo',
	// Off
	'colorSysTextOff',
	'colorSysOff',
	'colorSysBgOff',
];

export const createColorVariables = (
	colorPrimary: string,
	brandColor: string,
	customColorVariables?: Partial<ColorVariables>,
): ColorVariables => {
	// Generated colors
	const colorPrimarySoft = setLightness(colorPrimary, '96%');

	const colorHead = setLightness(setSaturation(colorPrimary, '100%'), '10%');
	const colorText = setAlpha(colorHead, '0.8');
	const colorMediumShadow = setAlpha(colorHead, '0.08');
	const colorLightShadow = setAlpha(colorHead, '0.02');
	const colorDarkShadow = setAlpha(colorHead, '0.14');

	const colorBorder = setLightness(setSaturation(colorPrimary, '7%'), '78%');
	const colorCaption = setLightness(colorBorder, '43%');
	const colorNote = setLightness(colorBorder, '62%');
	const colorInactive = setLightness(colorBorder, '94%');

	// Fixed colors
	const colorHeadOnDark = 'hsla(0, 0%, 100%, 1)';
	const colorTextOnDark = setAlpha(colorHeadOnDark, '0.8');
	const colorLightShadowNoOpacity = '#F0F0F1';

	const colorBgBody = 'hsla(0, 0%, 100%, 1)';
	const colorBgOverlay = setAlpha(colorBgBody, '0.96');
	const colorBgSurface = 'hsla(0, 0%, 100%, 1)';

	// Fixed and not customizable system colors

	// Default
	const colorSysTextDefault = 'hsl(0, 0%, 45%)';
	const colorSysDefault = 'hsl(0, 0%, 57%)';
	const colorSysBgDefault = 'hsl(0, 0%, 98%)';

	// Alert
	const colorSysTextAlert = 'hsl(350, 90%, 45%)';
	const colorSysAlert = 'hsl(350, 90%, 51%)';
	const colorSysBgAlert = 'hsl(350, 90%, 96%)';

	// Warning
	const colorSysTextWarning = 'hsl(20, 82%, 41%)';
	const colorSysWarning = 'hsl(20, 82%, 56%)';
	const colorSysBgWarning = 'hsl(21, 81%, 96%)';
	// Success
	const colorSysTextSuccess = 'hsl(135, 60%, 32%)';
	const colorSysSuccess = 'hsl(135, 60%, 41%)';
	const colorSysBgSuccess = 'hsl(135, 60%, 96%)';
	// Info
	const colorSysTextInfo = 'hsl(186, 61%, 33%)';
	const colorSysInfo = 'hsl(186, 61%, 41%)';
	const colorSysBgInfo = 'hsl(185, 60%, 96%)';
	// Off
	const colorSysTextOff = 'hsl(0, 0%, 100%)';
	const colorSysOff = 'hsl(0, 0%, 81%)';
	const colorSysBgOff = 'hsl(0, 0%, 32%)';

	return {
		brandColor,
		colorPrimary,
		colorPrimarySoft: customColorVariables?.colorPrimarySoft || colorPrimarySoft,
		colorHead: customColorVariables?.colorHead || colorHead,
		colorText: customColorVariables?.colorText || colorText,
		colorMediumShadow: customColorVariables?.colorMediumShadow || colorMediumShadow,
		colorDarkShadow: customColorVariables?.colorDarkShadow || colorDarkShadow,
		colorLightShadow: customColorVariables?.colorLightShadow || colorLightShadow,
		colorBorder: customColorVariables?.colorBorder || colorBorder,
		colorCaption: customColorVariables?.colorCaption || colorCaption,
		colorNote: customColorVariables?.colorNote || colorNote,
		colorInactive: customColorVariables?.colorInactive || colorInactive,
		colorHeadOnDark: customColorVariables?.colorHeadOnDark || colorHeadOnDark,
		colorTextOnDark: customColorVariables?.colorTextOnDark || colorTextOnDark,
		colorBgBody: customColorVariables?.colorBgBody || colorBgBody,
		colorBgOverlay: customColorVariables?.colorBgOverlay || colorBgOverlay,
		colorBgSurface: customColorVariables?.colorBgSurface || colorBgSurface,
		colorSysTextDefault: customColorVariables?.colorSysTextDefault || colorSysTextDefault,
		colorSysDefault: customColorVariables?.colorSysDefault || colorSysDefault,
		colorSysBgDefault: customColorVariables?.colorSysBgDefault || colorSysBgDefault,
		colorSysTextAlert: customColorVariables?.colorSysTextAlert || colorSysTextAlert,
		colorSysAlert: customColorVariables?.colorSysAlert || colorSysAlert,
		colorSysBgAlert: customColorVariables?.colorSysBgAlert || colorSysBgAlert,
		colorSysTextWarning: customColorVariables?.colorSysTextWarning || colorSysTextWarning,
		colorSysWarning: customColorVariables?.colorSysWarning || colorSysWarning,
		colorSysBgWarning: customColorVariables?.colorSysBgWarning || colorSysBgWarning,
		colorSysTextSuccess: customColorVariables?.colorSysTextSuccess || colorSysTextSuccess,
		colorSysSuccess: customColorVariables?.colorSysSuccess || colorSysSuccess,
		colorSysBgSuccess: customColorVariables?.colorSysBgSuccess || colorSysBgSuccess,
		colorSysTextInfo: customColorVariables?.colorSysTextInfo || colorSysTextInfo,
		colorSysInfo: customColorVariables?.colorSysInfo || colorSysInfo,
		colorSysBgInfo: customColorVariables?.colorSysBgInfo || colorSysBgInfo,
		colorSysTextOff: customColorVariables?.colorSysTextOff || colorSysTextOff,
		colorSysOff: customColorVariables?.colorSysOff || colorSysOff,
		colorSysBgOff: customColorVariables?.colorSysBgOff || colorSysBgOff,
		colorLightShadowNoOpacity: customColorVariables?.colorLightShadowNoOpacity || colorLightShadowNoOpacity,
	};
};
