import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const VideoVisitIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<rect x={'0'} y={'0'} width={'20'} height={'20'}></rect>
				<path
					d={
						'M13.3333333,5 C13.7916667,5 14.1666667,5.375 14.1666667,5.83333333 L14.1666667,5.83333333 L14.1666667,8.75 L16.075,6.83333333 C16.6,6.30833333 17.5,6.68333333 17.5,7.425 L17.5,7.425 L17.5,12.5666667 C17.5,13.3083333 16.6,13.6833333 16.075,13.1583333 L16.075,13.1583333 L14.1666667,11.25 L14.1666667,14.1666667 C14.1666667,14.625 13.7916667,15 13.3333333,15 L13.3333333,15 L3.33333333,15 C2.875,15 2.5,14.625 2.5,14.1666667 L2.5,14.1666667 L2.5,5.83333333 C2.5,5.375 2.875,5 3.33333333,5 L3.33333333,5 Z M11.925,7.32708333 C11.6,7.00208333 11.075,7.00208333 10.75,7.32708333 L10.75,7.32708333 L7.05833333,11.04375 L5.91666667,9.90208333 C5.6,9.57708333 5.075,9.57708333 4.75,9.90208333 L4.75,9.90208333 L4.74166667,9.91041667 C4.425,10.2270833 4.425,10.74375 4.74166667,11.06875 L4.74166667,11.06875 L6.46666667,12.8104167 C6.79166667,13.1354167 7.325,13.1354167 7.65,12.8104167 L7.65,12.8104167 L11.9333333,8.49375 C12.25,8.17708333 12.25,7.66041667 11.9333333,7.33541667 L11.9333333,7.33541667 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
