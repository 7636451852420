export const TRANSLATIONS = {
	ru: {
		translation: {
			doctorSearchFiltersApply: 'Применить фильтры',
			doctorSearchFiltersReset: 'Сбросить фильтры',
			noConnectionLabel: 'Нет соединения. Подключение...',
			sessionExpiredLabel: 'Срок действия этой сессии истек',
			gettingLocation: 'Нахождение вашего местоположения...',
			nextAvailability: 'Следующее наличие',
			availableWithin: 'В течение {{days}} дней',
			nextDay: 'Завтра',
			distanceKms: '{{distance}} км',
			oneMile: '1 миля',
			distanceMiles: '{{distance}} миль',
			confirmOpenLink: 'Ссылка откроется в новой вкладке.',
			progressBarRemainingTimeLessThanMinute: 'Осталось меньше 1 минуты',
			progressBarRemainingTime: '{{minutes}} мин осталось',
			userInputPlaceholder: 'Напишите',
			languageSelectorTitle: 'Язык',
			menuTitle: 'Привет',
			menuWelcomeText: 'Я автоматизированный помощник. Я здесь, чтобы помочь вам найти то, что вам нужно.',
			emergencyClinicOpen: 'Открыто 24 часа',
			map: 'Карта',
			call: 'Позвонить',
			continue: 'Продолжить',
			month: 'Месяц',
			day: 'День',
			year: 'Год',
			dateInputError: 'Введите правильное значение для {{label}}, между {{min}} и {{max}}.',
			dateInputFullDateFebruraryError: 'Неправильная дата, так как в феврале только {{days}} дней в {{year}}',
			dateInputFullDateError: 'Неправильная дата, так как в {{month}} только {{days}}',
			phoneNumberInputLabel: 'Номер телефона',
			phoneInputInvalidCharsError: 'Номер телефона содержит недопустимые символы. Допустимые символы ()+-.',
			phoneInputNotInternationalUSError:
				'Значение номера телефона должно быть действительным номером телефона в США. Пожалуйста, введите действительный номер телефона в США',
			phoneInputInternationalUSTooShortError:
				'Номер телефона слишком короткий. Пожалуйста, введите 11-значный номер телефона.',
			phoneInputInternationalUSTooLongError:
				'Номер телефона слишком длинный. Пожалуйста, введите 11-значный номер телефона.',
			phoneInputTooLongError: 'Номер телефона слишком длинный. Пожалуйста, введите 10-значный номер телефона.',
			phoneInputTooShortError: 'Номер телефона слишком короткий. Пожалуйста, введите 10-значный номер телефона.',
			doctorSearchDoctors: 'Доктора',
			doctorSearchFilters: 'Фильтры',
			tryAgain: 'Попробовать еще раз',
			numberOfResults: '{{doctorsNumber}} результат',
			numberOfResults_plural: '{{doctorsNumber}} результатов',
			doctorSearchShowMore: 'Показать еще',
			doctorSearchFilterLabel: 'Фильтр',
			doctorSearchAcceptsNewPatients: 'Принимает новых пациентов',
			doctorSearchNoNewPatients: 'Не принимает новых пациентов',
			doctorSearchAvailable: 'Доступный',
			doctorSearchVirtualVisits: 'Доступны виртуальные визиты',
			doctorSearchEmptyByFiltersTitle: 'Нет доступных докторов',
			doctorSearchEmptyByFiltersDescription:
				'К сожалению нет доступных докторов, которые совпадают с выбранными фильтрам',
			doctorSearchDistance: 'расстояние',
			doctorSearchErrorTitle: 'Врачи не найдены',
			doctorSearchErrorDescription: 'К сожалению, мы не смогли найти врачей.',
			emailValidationAddSymbolMissing: "Пожалуйста, добавьте символ '@' в адрес электронной почты.",
			emailValidationIncorrectDomain:
				"Пожалуйста, введите действительное доменное имя в адресе электронной почты, например, 'example.com'.",
			emailValidationGenericError:
				"Пожалуйста, введите действительный адрес электронной почты. Формат должен быть 'user@example.com'.",
			imageUploaderChoosePhotoLabel: 'Выберите изображение',
			imageUploadFileSizeError: 'Размер файла превышает 10 МБ. Пожалуйста, выберите другое изображение.',
			imageUploadFileNumberError: 'Вы можете загрузить только до 4 файлов',
			imageUploadFileGenericError: 'Ошибка загрузки изображения',
			cancel: 'Отменить',
			imageUploaderDiscard: 'Удалить фотографию',
			imageUploaderProgressLabel: 'Загружается',
			takePhoto: 'Сделать фотографию',
			imageUploaderNoPreview: 'Просмотр недоступен',
			passwordResetPasswordPlaceholder: 'Новый пароль',
			passwordResetConfirmPasswordPlaceholder: 'Подтвердите пароль',
			passwordResetConfirmError: 'Пароли не совпадают',
			feedbackSubmit: 'Отправить отзыв',
			LLMResultTextSources: 'Источники текста',
			LLMTooltipDescription: 'Этот контент был создан с использованием искусственного интеллекта.',
			LLMTooltipLabel: 'Сгенерировано ИИ',
			passwordResetChangeLabel: 'Изменить пароль',
			liveChatNotSentLabel: 'Не отправлено',
			liveChatRetryLink: 'Повторить попытку',
			liveChatSendingLabel: 'Отправка...',
			sendFreeTextDisabledPlaceholder: 'Начните печатать, затем выберите',
			weightLabel: 'Вес',
			weightInputInvalidCharsError: 'Пожалуйста, введите действительный вес от 1 до {{value}} {{unit}}.',
			kg: 'кг',
			lbs: 'фунтов',
		},
	},
	ar: {
		translation: {
			doctorSearchFiltersApply: 'Apply filters',
			doctorSearchFiltersReset: 'Reset filters',
			noConnectionLabel: 'No connection. Reconnecting...',
			sessionExpiredLabel: 'This session has expired',
			gettingLocation: 'Getting your location...',
			nextAvailability: 'Next availability',
			availableWithin: 'Within {{days}} days',
			nextDay: 'Tomorrow',
			distanceKms: '{{distance}} km',
			oneMile: '1 mile',
			distanceMiles: '{{distance}} miles',
			confirmOpenLink: 'Link will open in a new tab.',
			progressBarRemainingTimeLessThanMinute: 'Less than 1 min left',
			progressBarRemainingTime: '{{minutes}} min left',
			userInputPlaceholder: 'Message',
			languageSelectorTitle: 'Language',
			menuTitle: 'Hello',
			menuWelcomeText: 'I’m an automated assistant. I’m here to help you find what you need.',
			emergencyClinicOpen: 'Open 24 hours',
			map: 'Map',
			call: 'Call',
			continue: 'Сontinue',
			month: 'Month',
			day: 'Day',
			year: 'Year',
			dateInputError: 'Please enter a valid {{label}} value between {{min}} and {{max}}.',
			dateInputFullDateFebruraryError: 'The date is not valid as February only has {{days}} in {{year}}',
			dateInputFullDateError: 'The date is not valid as {{month}} only has {{days}}',
			phoneNumberInputLabel: 'Phone number',
			phoneInputInvalidCharsError: 'The Phone number contains invalid characters. Characters allowed ()+-.',
			phoneInputNotInternationalUSError:
				'The Phone number value must be a valid US phone number. Please enter valid US phone number',
			phoneInputInternationalUSTooShortError:
				'The Phone number value is too short. Please enter an 11-digit phone number.',
			phoneInputInternationalUSTooLongError:
				'The Phone number value is too long. Please enter an 11-digit phone number.',
			phoneInputTooLongError: 'The Phone number value is too long. Please enter a 10-digit phone number.',
			phoneInputTooShortError: 'The Phone number value is too short. Please enter a 10-digit phone number.',
			doctorSearchDoctors: 'Doctors',
			doctorSearchFilters: 'Filters',
			tryAgain: 'Try Again',
			numberOfResults: '{{doctorsNumber}} result',
			numberOfResults_plural: '{{doctorsNumber}} results',
			doctorSearchShowMore: 'Show more',
			doctorSearchFilterLabel: 'Filter',
			doctorSearchAcceptsNewPatients: 'Accepts New Patients',
			doctorSearchNoNewPatients: 'No New Patients',
			doctorSearchAvailable: 'Available',
			doctorSearchVirtualVisits: 'Virtual visits available',
			doctorSearchEmptyByFiltersTitle: 'No doctors available',
			doctorSearchEmptyByFiltersDescription: "Unfortunately, we couldn't find any doctors matching your filters.",
			doctorSearchDistance: 'distance',
			doctorSearchErrorTitle: "Can't find doctors",
			doctorSearchErrorDescription: 'Unfortunately, we are unable to search for doctors.',
			emailValidationAddSymbolMissing: "Please include the '@' symbol in the email address.",
			emailValidationIncorrectDomain:
				"Please enter a valid domain name in the email address, such as 'example.com'",
			emailValidationGenericError: "Please enter a valid email address. The format should be 'user@example.com'.",
			imageUploaderChoosePhotoLabel: 'Upload a photo',
			imageUploadFileSizeError: 'File size exceeded 10MB. Please select another image.',
			imageUploadFileNumberError: 'You can only upload up to 4 files',
			imageUploadFileGenericError: 'Error while uploading image',
			cancel: 'Cancel',
			imageUploaderDiscard: 'Discard photo',
			imageUploaderProgressLabel: 'Uploading',
			takePhoto: 'Take photo',
			imageUploaderNoPreview: 'The preview is not available',
			passwordResetPasswordPlaceholder: 'New Password',
			passwordResetConfirmPasswordPlaceholder: 'Confirm Password',
			passwordResetConfirmError: 'Passwords do not match',
			feedbackSubmit: 'Submit feedback',
			LLMResultTextSources: 'Text Sources',
			LLMTooltipDescription: 'This content was generated using artificial intelligence.',
			LLMTooltipLabel: 'Generated by AI',
			passwordResetChangeLabel: 'Change Password',
			liveChatNotSentLabel: 'Not sent',
			liveChatRetryLink: 'Retry',
			liveChatSendingLabel: 'Sending...',
			sendFreeTextDisabledPlaceholder: 'Start typing, then select option',
			weightLabel: 'Weight',
			weightInputInvalidCharsError: 'Please enter a valid weight between 1 and {{value}} {{unit}}.',
			kg: 'kg',
			lbs: 'lbs',
		},
	},
	de: {
		translation: {
			doctorSearchFiltersApply: 'Wenden Sie Filter an',
			doctorSearchFiltersReset: 'Filter zurücksetzen',
			noConnectionLabel: 'Keine Verbindung. Wiederverbindung...',
			sessionExpiredLabel: 'Diese Sitzung ist abgelaufen',
			gettingLocation: 'Getting your location...',
			nextAvailability: 'Nächste Verfügbarkeit',
			availableWithin: 'Innerhalb von {{days}} Tagen',
			nextDay: 'Morgen',
			distanceKms: '{{distance}} km',
			oneMile: '1 meile',
			distanceMiles: '{{distance}} Meilen',
			confirmOpenLink: 'Der Link wird in einem neuen Tab geöffnet.',
			progressBarRemainingTimeLessThanMinute: 'Noch weniger als eine Minute',
			progressBarRemainingTime: 'Noch {{minutes}} Min',
			userInputPlaceholder: 'Schreiben',
			languageSelectorTitle: 'Sprache',
			menuTitle: 'Hallo',
			menuWelcomeText:
				'Ich bin ein automatisierter Assistent. Ich bin hier, um Ihnen zu helfen, das zu finden, was Sie brauchen.',
			emergencyClinicOpen: '24 Stunden geöffnet',
			map: 'Karte',
			call: 'Forderung',
			continue: 'Weitermachen',
			month: 'Monat',
			day: 'Tag',
			year: 'Jahr',
			dateInputError: 'Bitte geben Sie einen gültigen {{label}} Wert zwischen {{min}} und {{max}} ein.',
			dateInputFullDateFebruraryError: 'Das Datum ist ungültig, da Februar nur {{days}} Tage im {{year}} hat',
			dateInputFullDateError: 'Das Datum ist nicht gültig, da {{month}} nur {{days}} hat',
			phoneNumberInputLabel: 'Telefonnummer',
			phoneInputInvalidCharsError: 'Die Telefonnummer enthält ungültige Zeichen. Zulässige Zeichen ()+-.',
			phoneInputNotInternationalUSError:
				'Die Telefonnummer muss eine gültige US-Telefonnummer sein. Bitte geben Sie eine gültige US-Telefonnummer ein',
			phoneInputInternationalUSTooShortError:
				'Die Telefonnummer ist zu kurz. Bitte geben Sie eine 11-stellige Telefonnummer ein.',
			phoneInputInternationalUSTooLongError:
				'Die Telefonnummer ist zu lang. Bitte geben Sie eine 11-stellige Telefonnummer ein.',
			phoneInputTooLongError:
				'Die Telefonnummer ist zu lang. Bitte geben Sie eine 10-stellige Telefonnummer ein.',
			phoneInputTooShortError:
				'Die Telefonnummer ist zu kurz. Bitte geben Sie eine 10-stellige Telefonnummer ein.',
			doctorSearchDoctors: 'Ärztinnen',
			doctorSearchFilters: 'Filter',
			tryAgain: 'Ersuchen Sie es erneut',
			numberOfResults: '{{doctorsNumber}} ergebnis',
			numberOfResults_plural: '{{doctorsNumber}} ergebnisse',
			doctorSearchShowMore: 'Zeig mehr',
			doctorSearchFilterLabel: 'Filter',
			doctorSearchAcceptsNewPatients: 'Akzeptiert neue Patienten',
			doctorSearchNoNewPatients: 'Keine neuen Patienten',
			doctorSearchAvailable: 'Verfügbar',
			doctorSearchVirtualVisits: 'Virtuelle besuche verfügbar',
			doctorSearchEmptyByFiltersTitle: 'Keine Ärzte verfügbar',
			doctorSearchEmptyByFiltersDescription: 'Keine Ärzte verfügbar',
			doctorSearchDistance: 'distanz',
			doctorSearchErrorTitle: 'Ärzte nicht gefunden',
			doctorSearchErrorDescription: 'Leider können wir keine Ärzte suchen.',
			emailValidationAddSymbolMissing: "Bitte fügen Sie das '@'-Symbol in die E-Mail-Adresse ein.",
			emailValidationIncorrectDomain:
				"Bitte geben Sie eine gültige Domain in der E-Mail-Adresse an, wie zum Beispiel 'example.com'.",
			emailValidationGenericError:
				"Bitte geben Sie eine gültige E-Mail-Adresse ein. Das Format sollte 'user@example.com' sein.",
			imageUploaderChoosePhotoLabel: 'Foto hochladen',
			imageUploadFileSizeError: 'Die Dateigröße überschreitet 10 MB. Bitte wählen Sie ein anderes Bild aus.',
			imageUploadFileNumberError: 'Sie können nur bis zu 4 Dateien hochladen.',
			imageUploadFileGenericError: 'Fehler beim Hochladen des Bildes',
			cancel: 'Stornieren',
			imageUploaderDiscard: 'Foto verwerfen',
			imageUploaderProgressLabel: 'Hochladen',
			takePhoto: 'Ein Foto machen',
			imageUploaderNoPreview: 'Die Vorschau ist nicht verfügbar',
			passwordResetPasswordPlaceholder: 'Neues Passwort',
			passwordResetConfirmPasswordPlaceholder: 'Passwort bestätigen',
			passwordResetConfirmError: 'Passwörter stimmen nicht überein',
			feedbackSubmit: 'Submit feedback',
			LLMResultTextSources: 'Textquellen',
			LLMTooltipDescription: 'Dieser Inhalt wurde mithilfe künstlicher Intelligenz erstellt.',
			LLMTooltipLabel: 'Generiert von KI',
			passwordResetChangeLabel: 'Passwort ändern',
			liveChatNotSentLabel: 'Nicht gesendet',
			liveChatRetryLink: 'Wiederholen',
			liveChatSendingLabel: 'Senden...',
			sendFreeTextDisabledPlaceholder: 'Beginnen Sie zu tippen, wählen Sie dann eine Option aus',
			weightLabel: 'Gewicht',
			weightInputInvalidCharsError: 'Bitte geben Sie ein gültiges Gewicht zwischen 1 und {{value}} {{unit}} ein.',
			kg: 'kg',
			lbs: 'lbs',
		},
	},
	en: {
		translation: {
			doctorSearchFiltersApply: 'Apply filters',
			doctorSearchFiltersReset: 'Reset filters',
			date: 'Date',
			noConnectionLabel: 'No connection. Reconnecting...',
			sessionExpiredLabel: 'This session has expired',
			datePickerMinDateErrorLabel: 'Selected date can’t be before today',
			gettingLocation: 'Getting your location...',
			nextAvailability: 'Next availability',
			availableWithin: 'Within {{days}} days',
			nextDay: 'Tomorrow',
			distanceKms: '{{distance}} km',
			oneMile: '1 mile',
			distanceMiles: '{{distance}} miles',
			confirmOpenLink: 'Link will open in a new tab.',
			progressBarRemainingTimeLessThanMinute: 'Less than 1 min left',
			progressBarRemainingTime: '{{minutes}} min left',
			userInputPlaceholder: 'Message',
			appointmentSchedulerHeaderTitle: 'Scheduling for',
			appointmentSchedulerHeaderChangeDoctor: 'Change',
			nextAvailable: 'Next available',
			nextAvailableNoSlots: 'No slots available for this doctor',
			nextAvailableErrorRequestSlots: 'Unable to display slots',
			nextAvailableChangeDoctorButtonLabel: 'Change doctor/clinic',
			nextAvailableTryChangeDoctor: 'If it’s taking too long, you can try changing doctor.',
			tryAgain: 'Try Again',
			nextAvailableErrorIssuePersists: 'If issue persists, try changing doctor.',
			PDTLabel: 'Pacific Daylight Time',
			nextAvailableSeeAllSlots: 'See all',
			nextAvailableSearching: 'Searching...',
			nextAvailableStillSearching: 'Still Searching...',
			appointmentSchedulerSlotsAvailable: 'slots available',
			appointmentSchedulerPaginationPrev: 'Earlier',
			appointmentSchedulerPaginationNext: 'Later',
			appointmentSchedulerNoSlots: 'No slots available this month',
			appointmentSchedulerScheduleButtonLabelDefault: 'Select a time',
			appointmentSchedulerScheduleButtonLabel: 'Schedule for {{day}} at {{time}}',
			appointmentSummaryAdditionalInfoTitle: 'Additional information',
			appointmentSummaryContactTitle: 'Contact',
			appointmentSummaryChangeDoctor: 'Make a change',
			appointmentSummaryCompleted: 'Scheduling Completed',
			appointmentSummaryScheduledForLabel: 'Scheduled for',
			appointmentSummaryArriveLabel: 'Arrive at',
			appointmentSummaryLocationTitle: 'Location',
			appointmentSummaryWithLabel: 'Appointment with',
			languageSelectorTitle: 'Language',
			menuTitle: 'Hello',
			menuWelcomeText: 'I’m an automated assistant. I’m here to help you find what you need.',
			emergencyClinicOpen: 'Open 24 hours',
			map: 'Map',
			call: 'Call',
			appointmentSchedulingCantShowSlots: "Can't show slots",
			appointmentSchedulingUnableToChangeSlots: 'Unable to load available slots.',
			appointmnetSchedulingNoSlots: 'No slots available for this doctor/clinic.',
			changeAppointment: 'Change appointment',
			cancelAppointment: 'Cancel appointment',
			addCalendar: 'Add to Calendar',
			helpfulnessSurveyText: 'Is this helpful?',
			liveChatWaitingInQueue: "You're in queue...",
			liveChatTransferingToNewAgent: 'Transferring to a new agent...',
			liveChatHeaderTitle: 'Chat',
			liveChatMainMenuTitle: 'Live Chat',
			liveChatStartingTitle: 'Starting Live Chat',
			liveChatStartingDescription:
				'Use live chat to ask any questions. It usually takes a few minutes to connect.',
			liveChatAlternativeWaitingMessage: 'Someone will join shortly...',
			liveChatStillInQueueMessage: 'You’re still in queue...',
			liveChatConfirmationModalYesLabel: 'Yes',
			liveChatConfirmationModalNoLabel: 'No',
			liveChatConfirmationModalTitle: 'Would you like to end Live Chat?',
			liveChatConfirmationModalDescription: 'Restarting the virtual assistant will end Live Chat.',
			liveChatThankYou: 'Thank you!',
			liveChatThankYouDone: 'Done',
			liveChatEndedMessage: 'Chat ended',
			liveChatStartedMessage: 'You started a chat',
			liveChatNotSentLabel: 'Not sent',
			liveChatRetryLink: 'Retry',
			liveChatSendingLabel: 'Sending...',
			sendFreeTextDisabledPlaceholder: 'Start typing, then select option',
			appointmentManagementWorkConflictReason: 'Conflict with work',
			appointmentManagementNoTransportReason: 'No transportation',
			appointmentManagementSeenElsewhereReason: 'Getting care elsewhere',
			appointmentManagementHighCostReason: 'Cost too high',
			appointmentManagementAnxiousReason: 'Feeling anxious',
			appointmentManagementOtherReason: 'Other reason',
			appointmentManagementCancelTitle: 'What’s the reason for canceling the appointment?',
			rescheduleAppointment: 'Reschedule Appointment',
			showMore: 'More...',
			showLess: 'Less...',
			appointmentDetails: 'Appointment Details',
			appointmentCancelNotificationSuccess: 'The appointment "{{appointmentTitle}}" was cancelled.',
			appointmentCancelNotificationWarning:
				'We were unable to cancel your appointment. Please contact the provider directly.',
			continue: 'Continue',
			month: 'Month',
			day: 'Day',
			year: 'Year',
			dateInputError: 'Please enter a valid {{label}} value between {{min}} and {{max}}.',
			dateInputFullDateFebruraryError: 'The date is not valid as February only has {{days}} in {{year}}',
			dateInputFullDateError: 'The date is not valid as {{month}} only has {{days}} days',
			phoneNumberInputLabel: 'Phone number',
			phoneInputInvalidCharsError: 'The Phone number contains invalid characters. Characters allowed ()+-.',
			phoneInputNotInternationalUSError:
				'The Phone number value must be a valid US phone number. Please enter valid US phone number',
			phoneInputInternationalUSTooShortError:
				'The Phone number value is too short. Please enter an 11-digit phone number.',
			phoneInputInternationalUSTooLongError:
				'The Phone number value is too long. Please enter an 11-digit phone number.',
			phoneInputTooLongError: 'The Phone number value is too long. Please enter a 10-digit phone number.',
			phoneInputTooShortError: 'The Phone number value is too short. Please enter a 10-digit phone number.',
			doctorSearchDoctors: 'Doctors',
			doctorSearchFilters: 'Filters',
			numberOfResults: '{{doctorsNumber}} result',
			numberOfResults_plural: '{{doctorsNumber}} results',
			doctorSearchShowMore: 'Show more',
			doctorSearchFilterLabel: 'Filter',
			doctorSearchAcceptsNewPatients: 'Accepts New Patients',
			doctorSearchNoNewPatients: 'No New Patients',
			doctorSearchAvailable: 'Available',
			doctorSearchVirtualVisits: 'Virtual visits available',
			doctorSearchEmptyByFiltersTitle: 'No doctors available',
			doctorSearchEmptyByFiltersDescription: "Unfortunately, we couldn't find any doctors matching your filters.",
			doctorSearchDistance: 'distance',
			doctorSearchErrorTitle: "Can't find doctors",
			doctorSearchErrorDescription: 'Unfortunately, we are unable to search for doctors.',
			emailValidationAddSymbolMissing: "Please include the '@' symbol in the email address.",
			emailValidationIncorrectDomain:
				"Please enter a valid domain name in the email address, such as 'example.com'",
			emailValidationGenericError: "Please enter a valid email address. The format should be 'user@example.com'.",
			imageUploaderChoosePhotoLabel: 'Upload a photo',
			imageUploadFileSizeError: 'File size exceeded 10MB. Please select another image.',
			imageUploadFileNumberError: 'You can only upload up to 4 files',
			imageUploadFileGenericError: 'Error while uploading image',
			cancel: 'Cancel',
			imageUploaderDiscard: 'Discard photo',
			imageUploaderProgressLabel: 'Uploading',
			takePhoto: 'Take photo',
			imageUploaderNoPreview: 'The preview is not available',
			passwordResetPasswordPlaceholder: 'New Password',
			passwordResetConfirmPasswordPlaceholder: 'Confirm Password',
			passwordResetConfirmError: 'Passwords do not match',
			feedbackSubmit: 'Submit feedback',
			LLMResultTextSources: 'Text Sources',
			LLMTooltipDescription: 'This content was generated using artificial intelligence.',
			LLMTooltipLabel: 'Generated by AI',
			passwordResetChangeLabel: 'Change Password',
			weightLabel: 'Weight',
			weightInputInvalidCharsError: 'Please enter a valid weight between 1 and {{value}} {{unit}}.',
			kg: 'kg',
			lbs: 'lbs',
		},
	},
	es: {
		translation: {
			doctorSearchFiltersApply: 'Aplicar filtros',
			doctorSearchFiltersReset: 'Restablecer filtros',
			date: 'Fecha',
			noConnectionLabel: 'Sin conexión. Reconectando...',
			sessionExpiredLabel: 'Esta sesión ha expirado',
			datePickerMinDateErrorLabel: 'La fecha seleccionada no puede ser anterior a hoy',
			gettingLocation: 'Buscando tu ubicación...',
			nextAvailability: 'Próxima disponibilidad',
			availableWithin: 'Dentro de {{days}} días',
			nextDay: 'Mañana',
			distanceKms: '{{distance}} km',
			oneMile: '1 milla',
			distanceMiles: '{{distance}} milas',
			confirmOpenLink: 'El enlace se abrirá en una nueva pestaña.',
			progressBarRemainingTimeLessThanMinute: 'Queda menos de 1 min',
			progressBarRemainingTime: 'Quedan {{minutes}} min',
			userInputPlaceholder: 'Mensaje',
			appointmentSchedulerHeaderTitle: 'Cita para',
			appointmentSchedulerHeaderChangeDoctor: 'Cambiar',
			nextAvailable: 'Siguiente disponible',
			nextAvailableNoSlots: 'No puedo mostrar las ranuras para este doutor',
			nextAvailableErrorRequestSlots: 'No se pueden mostrar las ranuras',
			nextAvailableChangeDoctorButtonLabel: 'Cambio de doctor/clínica',
			nextAvailableTryChangeDoctor: 'Si está tardando demasiado, puede intentar cambiar de médico.',
			tryAgain: 'Intentar otra vez',
			nextAvailableErrorIssuePersists: 'Si el problema persiste, intente cambiar de médico.',
			PDTLabel: 'Hora del Pacifico',
			nextAvailableSeeAllSlots: 'Ver todo',
			nextAvailableSearching: 'Buscando...',
			nextAvailableStillSearching: 'Sigue Buscando...',
			appointmentSchedulerSlotsAvailable: 'horarios disponibles',
			appointmentSchedulerPaginationPrev: 'Más tarde',
			appointmentSchedulerPaginationNext: 'Más temprano',
			appointmentSchedulerNoSlots: 'No hay horarios disponibles este mes',
			appointmentSchedulerScheduleButtonLabelDefault: 'Selecciona una hora',
			appointmentSchedulerScheduleButtonLabel: 'Cita para {{day}} a las {{time}}',
			appointmentSummaryAdditionalInfoTitle: 'Información adicional',
			appointmentSummaryContactTitle: 'Contacto',
			appointmentSummaryChangeDoctor: 'Hacer un cambio',
			appointmentSummaryCompleted: 'Cita completa',
			appointmentSummaryScheduledForLabel: 'Programado para',
			appointmentSummaryArriveLabel: 'Llegar a las',
			appointmentSummaryLocationTitle: 'Localización',
			appointmentSummaryWithLabel: 'Cita com',
			languageSelectorTitle: 'Idioma',
			menuTitle: 'Hola',
			menuWelcomeText: 'Soy un asistente automático. Puedo ayudarte a encontrar lo que necesitas.',
			emergencyClinicOpen: 'Abierto las 24 horas',
			map: 'Mapa',
			call: 'Llamar',
			appointmentSchedulingCantShowSlots: 'No se pueden mostrar las ranuras',
			appointmentSchedulingUnableToChangeSlots: 'No se pueden cargar las ranuras disponibles.',
			appointmnetSchedulingNoSlots: 'No hay plazas disponibles para este médico/clínica.',
			changeAppointment: 'Cambiar cita',
			cancelAppointment: 'Cancelar cita',
			addCalendar: 'Añadir al calendario',
			helpfulnessSurveyText: '¿Es esto útil?',
			liveChatWaitingInQueue: 'Estas en la cola...',
			liveChatTransferingToNewAgent: 'Transferir a un nuevo agente...',
			liveChatHeaderTitle: 'Chat',
			liveChatMainMenuTitle: 'Live Chat',
			liveChatStartingTitle: 'Starting Live Chat',
			liveChatStartingDescription:
				'Use el chat en vivo para hacer cualquier pregunta. Suele tardar unos minutos en conectarse.',
			liveChatAlternativeWaitingMessage: 'Alguien se unirá en breve...',
			liveChatStillInQueueMessage: 'Todavía estás en la cola...',
			liveChatConfirmationModalYesLabel: 'Yes',
			liveChatConfirmationModalNoLabel: 'No',
			liveChatConfirmationModalTitle: 'Would you like to end Live Chat?',
			liveChatConfirmationModalDescription: 'Restarting the virtual assistant will end Live Chat.',
			liveChatThankYou: 'Gracias!',
			liveChatThankYouDone: 'Hecho',
			liveChatEndedMessage: 'Chat finalizado',
			liveChatStartedMessage: 'Has iniciado un chat',
			liveChatNotSentLabel: 'No enviado',
			liveChatRetryLink: 'Rever',
			liveChatSendingLabel: 'Enviando...',
			sendFreeTextDisabledPlaceholder: 'Empieza a escribir, luego haz una selección',
			rescheduleAppointment: 'Re-agenda de citas',
			showMore: 'Más...',
			showLess: 'Menos...',
			appointmentManagementWorkConflictReason: 'Conflicto con el trabajo',
			appointmentManagementNoTransportReason: 'No hay transporte',
			appointmentManagementSeenElsewhereReason: 'Ser visto en otra parte',
			appointmentManagementHighCostReason: 'costo demasiado alto',
			appointmentManagementAnxiousReason: 'Sentirse ansioso',
			appointmentManagementOtherReason: 'Otra razon',
			appointmentManagementCancelTitle: '¿Cuál es el motivo de la cancelación de la cita?',
			appointmentDetails: 'Detalles de la cita',
			appointmentCancelNotificationSuccess: 'La cita "{{appointmentTitle}}" fue cancelada.',
			appointmentCancelNotificationWarning: 'No pudimos cancelar su cita. Póngase en contacto con su proveedor',
			continue: 'Continuar',
			month: 'Mes',
			day: 'Día',
			year: 'Año',
			dateInputError: 'Ingrese un valor válido {{label}} entre {{min}} y {{max}}.',
			dateInputFullDateFebruraryError:
				'La fecha no es válida ya que febrero solo tiene {{days}} días en {{year}}',
			dateInputFullDateError: 'La fecha no es válida ya que {{month}} solo tiene {{days}} días',
			phoneNumberInputLabel: 'Número de teléfono',
			phoneInputInvalidCharsError:
				'El número de teléfono contiene caracteres no válidos. Caracteres permitidos ()+-.',
			phoneInputNotInternationalUSError:
				'El número de teléfono debe ser un número de teléfono válido de EE. UU. Ingrese un número de teléfono válido de EE. UU.',
			phoneInputInternationalUSTooLongError:
				'El número de teléfono es demasiado largo. Ingrese un número de teléfono de 11 dígitos.',
			phoneInputInternationalUSTooShortError:
				'El número de teléfono es demasiado corto. Ingrese un número de teléfono de 11 dígitos.',
			phoneInputTooLongError:
				'El número de teléfono es demasiado largo. Ingrese un número de teléfono de 10 dígitos.',
			phoneInputTooShortError:
				'El número de teléfono es demasiado corto. Ingrese un número de teléfono de 10 dígitos.',
			doctorSearchDoctors: 'Doctores',
			doctorSearchFilters: 'Filtros',
			numberOfResults: '{{doctorsNumber}} resultado',
			numberOfResults_plural: '{{doctorsNumber}} resultados',
			doctorSearchShowMore: 'Mostrar más',
			doctorSearchFilterLabel: 'Filtrar',
			doctorSearchAcceptsNewPatients: 'Acepta nuevos pacientes',
			doctorSearchNoNewPatients: 'Sin nuevos pacientes',
			doctorSearchAvailable: 'Disponible',
			doctorSearchVirtualVisits: 'Visitas virtuales disponibles',
			doctorSearchEmptyByFiltersTitle: 'No hay médicos disponibles',
			doctorSearchEmptyByFiltersDescription:
				'Lamentablemente, no pudimos encontrar ningún médico que coincida con sus filtros.',
			doctorSearchDistance: 'distancia',
			doctorSearchErrorTitle: 'No se pueden encontrar médicos',
			doctorSearchErrorDescription: 'Lamentablemente, no podemos buscar médicos.',
			emailValidationAddSymbolMissing: "Por favor, incluya el símbolo '@' en la dirección de correo electrónico.",
			emailValidationIncorrectDomain:
				"Por favor, ingrese un nombre de dominio válido en la dirección de correo electrónico, como 'example.com'.",
			emailValidationGenericError:
				"Por favor, ingrese una dirección de correo electrónico válida. El formato debe ser 'user@example.com'.",
			imageUploaderChoosePhotoLabel: 'Subir una foto',
			imageUploadFileSizeError: 'El tamaño del archivo supera los 10 MB. Por favor, selecciona otra imagen',
			imageUploadFileNumberError: 'Solo puedes cargar hasta 4 archivos.',
			imageUploadFileGenericError: 'Error al subir la imagen',
			cancel: 'Cancelar',
			imageUploaderDiscard: 'Descartar foto',
			imageUploaderProgressLabel: 'Subiendo',
			takePhoto: 'Tomar foto',
			imageUploaderNoPreview: 'La vista previa no está disponible',
			passwordResetPasswordPlaceholder: 'Nueva contraseña',
			passwordResetConfirmPasswordPlaceholder: 'Confirmar contraseña',
			passwordResetConfirmError: 'Las contraseñas no coinciden',
			feedbackSubmit: 'Submit feedback',
			LLMResultTextSources: 'Fuentes de texto',
			LLMTooltipDescription: 'Este contenido fue generado utilizando inteligencia artificial.',
			LLMTooltipLabel: 'Generado por IA',
			passwordResetChangeLabel: 'Cambiar contraseña',
			weightLabel: 'Peso',
			weightInputInvalidCharsError: 'Por favor, introduzca un peso válido entre 1 y {{value}} {{unit}}.',
			kg: 'kg',
			lbs: 'lbs',
		},
	},
	pt: {
		translation: {
			doctorSearchFiltersApply: 'Aplicar filtros',
			doctorSearchFiltersReset: 'Redefinir filtros',
			noConnectionLabel: 'Sem conexão. Reconectando...',
			sessionExpiredLabel: 'Esta sessão expirou',
			gettingLocation: 'A determinar a sua localização...',
			nextAvailability: 'Próxima disponibilidade',
			availableWithin: 'Dentro de {{days}} dias',
			nextDay: 'Amanhã',
			distanceKms: '{{distance}} km',
			oneMile: '1 milha',
			distanceMiles: '{{distance}} milhas',
			confirmOpenLink: 'A ligação irá abrir numa página nova.',
			progressBarRemainingTimeLessThanMinute: 'Resta menos de 1 minuto',
			progressBarRemainingTime: 'Restam {{minutes}} min',
			userInputPlaceholder: 'Escreva',
			languageSelectorTitle: 'Língua',
			menuTitle: 'Olá',
			menuWelcomeText: 'Eu sou um assistente automatizado. Estou aqui para ajudá-lo a encontrar o que precisa.',
			emergencyClinicOpen: 'Aberto 24 horas',
			map: 'Mapa',
			call: 'Ligar',
			continue: 'Continuar',
			month: 'Mês',
			day: 'Dia',
			year: 'Ano',
			dateInputError: 'Por favor, digite um valor válido {{label}} entre {{min}} e {{max}}.',
			dateInputFullDateFebruraryError:
				'A data não é válida porque o Fevereiro tem apenas {{days}} dias em {{year}}',
			dateInputFullDateError: 'A data não é válida porque {{month}} tem apenas {{days}} dias',
			phoneNumberInputLabel: 'Telefone',
			phoneInputInvalidCharsError:
				'O número de telefone contém caracteres inválidos. Caracteres permitidos ()+-.',
			phoneInputNotInternationalUSError:
				'O número de telefone deve ser um número de telefone válido nos EUA. Insira um número de telefone válido dos EUA',
			phoneInputInternationalUSTooLongError:
				'O número de telefone é muito longo. Insira um número de telefone de 11 dígitos.',
			phoneInputInternationalUSTooShortError:
				'O número de telefone é muito curto. Insira um número de telefone de 11 dígitos.',
			phoneInputTooLongError: 'O número de telefone é muito longo. Insira um número de telefone de 10 dígitos.',
			phoneInputTooShortError: 'O número de telefone é muito curto. Insira um número de telefone de 10 dígitos.',
			doctorSearchDoctors: 'Médicos',
			doctorSearchFilters: 'Filtros',
			numberOfResults: '{{doctorsNumber}} resultado',
			numberOfResults_plural: '{{doctorsNumber}} resultados',
			doctorSearchShowMore: 'Mostre mais',
			tryAgain: 'Tentar novamente',
			doctorSearchFilterLabel: 'Filtros',
			doctorSearchAcceptsNewPatients: 'Aceita Novos Pacientes',
			doctorSearchNoNewPatients: 'Sem Novos Pacientes',
			doctorSearchAvailable: 'Disponível',
			doctorSearchVirtualVisits: 'Visitas virtuais disponíveis',
			doctorSearchEmptyByFiltersTitle: 'Nenhum médico disponível',
			doctorSearchEmptyByFiltersDescription:
				'Infelizmente, não encontramos médicos que correspondam aos seus filtros.',
			doctorSearchDistance: 'distância',
			doctorSearchErrorTitle: 'Não é possível encontrar médicos',
			doctorSearchErrorDescription: 'Infelizmente, não podemos procurar médicos.',
			emailValidationAddSymbolMissing: "Por favor, inclua o símbolo '@' no endereço de e-mail.",
			emailValidationIncorrectDomain:
				"Por favor, insira um nome de domínio válido no endereço de e-mail, como 'example.com'.",
			emailValidationGenericError:
				"Por favor, insira um endereço de e-mail válido. O formato deve ser 'user@example.com'.",
			imageUploaderChoosePhotoLabel: 'Enviar uma foto',
			imageUploadFileSizeError: 'O tamanho do arquivo excede 10 MB. Por favor, selecione outra imagem.',
			imageUploadFileNumberError: 'Só pode fazer upload até 4 arquivos',
			imageUploadFileGenericError: 'Erro ao enviar a imagem',
			cancel: 'Cancelar',
			imageUploaderDiscard: 'Descartar foto',
			imageUploaderProgressLabel: 'Enviando',
			takePhoto: 'Tirar foto',
			imageUploaderNoPreview: 'A visualização não está disponível',
			passwordResetPasswordPlaceholder: 'Nova senha',
			passwordResetConfirmPasswordPlaceholder: 'Confirmar senha',
			passwordResetConfirmError: 'Senhas não correspondem',
			feedbackSubmit: 'Submit feedback',
			LLMResultTextSources: 'Fontes de texto',
			LLMTooltipDescription: 'Este conteúdo foi gerado usando inteligência artificial.',
			LLMTooltipLabel: 'Gerado por IA',
			passwordResetChangeLabel: 'Alterar senha',
			liveChatNotSentLabel: 'Não enviado',
			liveChatRetryLink: 'Tentar novamente',
			liveChatSendingLabel: 'A enviar...',
			sendFreeTextDisabledPlaceholder: 'Comece a digitar, depois faça uma seleção',
			weightLabel: 'Peso',
			weightInputInvalidCharsError: 'Por favor, insira um peso válido entre 1 e {{value}} {{unit}}.',
			kg: 'kg',
			lbs: 'lbs',
		},
	},
};
