import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { CARD_MAX_WIDTH } from '../../utils/sizes.utils';

export const useCarouselStyles = makeStyles<Theme, { activeStep: number }>(
	({
		palette: {
			sizes,
			borderRadius,
			colorScheme: { colorVariables },
		},
	}) => ({
		noPage: {
			marginBottom: sizes.size_6,
		},
		wrapper: {
			width: '100%',
		},
		root: {
			width: '100%',
			overflowX: 'hidden',
			padding: `0 ${sizes.size_1}%`,
			boxSizing: 'border-box',
			'& > div': {
				overflowX: 'visible !important',
				maxWidth: CARD_MAX_WIDTH,
				margin: '0 auto',
				'& > div': {
					padding: sizes.sizeHalf,
					'@media (min-width: 624px)': {
						padding: `${sizes.sizeHalf}px ${sizes.size_1}px`,
					},
				},
			},
		},
		arrowLeft: {
			transform: 'rotate(90deg)',
		},
		arrowRight: {
			transform: 'rotate(-90deg)',
		},
		button: {
			minWidth: 48,
			width: 48,
			height: 40,
			padding: `${sizes.sizeHalf}px ${sizes.size_1}px`,
			borderRadius: borderRadius.button,
			'&:hover': {
				backgroundColor: colorVariables.colorPrimarySoft,
				'& svg': {
					color: colorVariables.colorPrimary,
				},
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
		hidden: { visibility: 'hidden' },
		activeCard: {
			'& > div': {
				border: `1px solid ${colorVariables.colorCaption}`,
			},
		},
	}),
);
