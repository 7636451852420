import { makeStyles } from '@material-ui/core';

export const useChipMultiSelectStyles = makeStyles({
	root: {
		flexDirection: 'row-reverse',

		'& > span': {
			paddingLeft: 0,
			textAlign: 'left',
		},
		'& > svg': {
			marginLeft: 8,
			marginRight: 0,
		},
	},
	fullWidth: {
		maxWidth: '100%',
		width: '100%',
		justifyContent: 'space-between',
	},
	uncheckedIcon: {
		fill: 'white',
	},
	checkIcon: {
		fill: 'white',
		position: 'absolute',
		right: 20,
	},
});
