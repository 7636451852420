import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const NewPatientIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 20 0 20 20 0 20'}></polygon>
				<path
					d={
						'M10,16.6666667 L9.28333333,15.95 C8.3,14.9666667 8.30833333,13.3666667 9.3,12.4 L10,11.7166667 C9.675,11.6833333 9.43333333,11.6666667 9.16666667,11.6666667 C6.94166667,11.6666667 2.5,12.7833333 2.5,15 L2.5,16.6666667 L10,16.6666667 Z M9.16666667,10 C11.0083333,10 12.5,8.50833333 12.5,6.66666667 C12.5,4.825 11.0083333,3.33333333 9.16666667,3.33333333 C7.325,3.33333333 5.83333333,4.825 5.83333333,6.66666667 C5.83333333,8.50833333 7.325,10 9.16666667,10'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
				<path
					d={
						'M13.4833333,16.4833333 C13.1583333,16.8083333 12.625,16.8083333 12.3,16.4833333 L10.575,14.7416667 C10.2583333,14.4166667 10.2583333,13.9 10.575,13.5833333 L10.5833333,13.575 C10.9083333,13.25 11.4333333,13.25 11.75,13.575 L12.8916667,14.7166667 L16.5833333,11 C16.9083333,10.675 17.4333333,10.675 17.7583333,11 L17.7666667,11.0083333 C18.0833333,11.3333333 18.0833333,11.85 17.7666667,12.1666667 L13.4833333,16.4833333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
