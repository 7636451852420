import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const StarRatingIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<rect x={'0'} y={'0'} width={'20'} height={'20'}></rect>
				<path
					d={
						'M12.025,8.33333333 L10.8,4.3 C10.5583333,3.50833333 9.44166667,3.50833333 9.20833333,4.3 L7.975,8.33333333 L4.26666667,8.33333333 C3.45833333,8.33333333 3.125,9.375 3.78333333,9.84166667 L6.81666667,12.0083333 L5.625,15.85 C5.38333333,16.625 6.28333333,17.25 6.925,16.7583333 L10,14.425 L13.075,16.7666667 C13.7166667,17.2583333 14.6166667,16.6333333 14.375,15.8583333 L13.1833333,12.0166667 L16.2166667,9.85 C16.875,9.375 16.5416667,8.34166667 15.7333333,8.34166667 L12.025,8.34166667 L12.025,8.33333333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
