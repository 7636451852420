import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const SandWatchIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g id={'Icons'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon id={'Path'} points={'0 0 20 0 20 20 0 20'}></polygon>
				<path
					d={
						'M6.66666667,1.66666667 C5.75,1.66666667 5,2.41666667 5,3.33333333 L5,5.975 C5,6.41666667 5.175,6.84166667 5.49166667,7.15833333 L8.33333333,10 L5.48333333,12.85 C5.175,13.1666667 5,13.5916667 5,14.0333333 L5,16.6666667 C5,17.5833333 5.75,18.3333333 6.66666667,18.3333333 L13.3333333,18.3333333 C14.25,18.3333333 15,17.5833333 15,16.6666667 L15,14.0333333 C15,13.5916667 14.825,13.1666667 14.5166667,12.8583333 L11.6666667,10 L14.5083333,7.16666667 C14.825,6.85 15,6.425 15,5.98333333 L15,3.33333333 C15,2.41666667 14.25,1.66666667 13.3333333,1.66666667 L6.66666667,1.66666667 Z M13.3333333,13.75 L13.3333333,15.8333333 C13.3333333,16.2916667 12.9583333,16.6666667 12.5,16.6666667 L7.5,16.6666667 C7.04166667,16.6666667 6.66666667,16.2916667 6.66666667,15.8333333 L6.66666667,13.75 L10,10.4166667 L13.3333333,13.75 Z M10,9.58333333 L6.66666667,6.25 L6.66666667,4.16666667 C6.66666667,3.70833333 7.04166667,3.33333333 7.5,3.33333333 L12.5,3.33333333 C12.9583333,3.33333333 13.3333333,3.70833333 13.3333333,4.16666667 L13.3333333,6.25 L10,9.58333333 Z'
					}
					id={'Shape'}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
