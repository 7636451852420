import {
	CollapsedWidgetCallActionItem,
	CollapsedWidgetConfigPayload,
	CollapsedWidgetConfigResponseModel,
	DismissButtonConfigResponseModel,
} from '../../context/api-service/api-service.model';
import { toLocale } from '../../models/languages.model';
import { Nullable } from '../../utils/types.utils';

export interface TooltipConfig {
	shouldShowTooltip: boolean;
	dismissTimeout: number;
	keepVisible: boolean;
}

export interface DismissButtonConfig {
	dismissTooltip: boolean;
	dismissCollapsedWidget: boolean;
	dismissOpenToggle: boolean;
	delay: number;
}

export interface MinimizeButtonConfig {
	oncePerSession: boolean;
	delay: number;
}

const DEFAULT_DISMISS_CONFIG: DismissButtonConfig = {
	dismissTooltip: false,
	dismissCollapsedWidget: false,
	dismissOpenToggle: false,
	delay: 0,
};

interface CTAEventData {
	eventName: 'CTAButtonClick';
	data: {
		scope: string;
	};
}
interface CollapsedButtonEventData {
	eventName: 'onUserClickCollapsedWidget';
	data: {
		collapsedWidgetConfig: CollapsedWidgetConfigResponseModel;
		clickIndex: number;
	};
}

type CollapsedWidgetButtonEventData = CTAEventData | CollapsedButtonEventData;
export interface CollapsedWidgetButtonConfig extends CollapsedWidgetCallActionItem {
	startingFlow?: CollapsedWidgetConfigResponseModel['startingFlow'];
	eventCallbackData?: CollapsedWidgetButtonEventData;
}

const generateLocalStorageString = (clientName: string, configSource: Nullable<string>): string =>
	`gyant_collapsed_${clientName.replace(/\s+/g, '')}${configSource ? `_${configSource}` : ''}`;

const getWasComponentShown = (name: string, configSource: Nullable<string>): boolean =>
	localStorage.getItem(generateLocalStorageString(name, configSource)) === '1';

export const toGetCollapsedWidgetConfigParams = (
	language: string,
	client: string,
	pathname: string,
): CollapsedWidgetConfigPayload => ({
	locale: toLocale(language),
	client,
	pathname,
});

export const setWasCollapsedWidgetButtonsComponentShown = (name: string, configSource: Nullable<string>): void =>
	localStorage.setItem(generateLocalStorageString(name, configSource), '1');

export const shouldLoadComponent = (
	showComponentOncePerSession: boolean,
	client: string,
	configSource: Nullable<string>,
): boolean => !(showComponentOncePerSession && getWasComponentShown(client, configSource));

export const toDismissButtomConfig = (dismissConfigModel: DismissButtonConfigResponseModel): DismissButtonConfig => ({
	dismissTooltip: dismissConfigModel.dismissTooltip || DEFAULT_DISMISS_CONFIG.dismissTooltip,
	dismissCollapsedWidget: dismissConfigModel.dismissCollapsedWidget || DEFAULT_DISMISS_CONFIG.dismissCollapsedWidget,
	dismissOpenToggle: dismissConfigModel.dismissOpenToggle || DEFAULT_DISMISS_CONFIG.dismissOpenToggle,
	delay: dismissConfigModel.delay || DEFAULT_DISMISS_CONFIG.delay,
});

export const getTooltipConfig = (
	showTooltipOncePerSession: boolean,
	keepTooltipVisible: boolean,
	tooltipVisibleTimeMs: number,
	client: string,
): TooltipConfig => {
	const sessionStorageTooltipName = `gyant-tooltip_${client}`;
	if (showTooltipOncePerSession) {
		const isTooltipWasShown = sessionStorage.getItem(sessionStorageTooltipName);

		if (isTooltipWasShown) {
			return {
				shouldShowTooltip: false,
				dismissTimeout: 0,
				keepVisible: false,
			};
		}
	}
	setTimeout(() => sessionStorage.setItem(sessionStorageTooltipName, '1'), 0);
	return {
		shouldShowTooltip: true,
		dismissTimeout: tooltipVisibleTimeMs,
		keepVisible: keepTooltipVisible,
	};
};
