import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useLoadingSlotsStyles = makeStyles<Theme>(({ palette: { sizes, borderRadius, colorScheme } }) => ({
	root: {
		padding: `0 ${sizes.size_1}%`,
		flexDirection: 'column',
		justifyContent: 'center',
		display: 'flex',
	},
	botMessage: {
		width: 174,
		height: 21,
		animation: '$blinker 1s linear infinite',
		marginBottom: sizes.size_2,
		marginTop: sizes.size_3,
		marginLeft: sizes.size_1,
	},
	emptySlot: {
		width: '100%',
		height: sizes.size_6,
		background: colorScheme.colorVariables.colorInactive,
		marginBottom: sizes.sizeHalf,
		borderRadius: borderRadius.button,
		animation: '$blinker 1s linear infinite',
	},
	opacityMedium: {
		opacity: 0.65,
	},
	opacityLow: {
		opacity: 0.3,
	},
	'@keyframes blinker': {
		'50%': {
			opacity: 0.2,
		},
	},
}));
