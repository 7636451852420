import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const FullArrowUpIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={''} width={'18'} height={'18'} {...props}>
		<path d={'M0 0h24v24H0V0z'} fill={'none'} />
		<path
			d={
				'M13 19V7.83l4.88 4.88c.39.39 1.03.39 1.42 0 .39-.39.39-1.02 0-1.41l-6.59-6.59c-.39-.39-1.02-.39-1.41 0l-6.6 6.58c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L11 7.83V19c0 .55.45 1 1 1s1-.45 1-1z'
			}
		/>
	</SvgIcon>
);
