import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ConfirmationAltIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon width={32} height={32} viewBox={'0 0 32 32'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<rect x={'0'} y={'0'} width={'32'} height={'32'}></rect>
				<path
					d={
						'M28.3866667,7.85333333 L15.0533333,21.1866667 C14.5333333,21.7066667 13.6933333,21.7066667 13.1733333,21.1866667 L9.4,17.4133333 C8.88,16.8933333 8.88,16.0533333 9.4,15.5333333 L9.4,15.5333333 C9.92,15.0133333 10.76,15.0133333 11.28,15.5333333 L14.1066667,18.36 L26.4933333,5.97333333 C27.0133333,5.45333333 27.8533333,5.45333333 28.3733333,5.97333333 L28.3733333,5.97333333 C28.9066667,6.49333333 28.9066667,7.33333333 28.3866667,7.85333333 Z M21.0266667,3.65333333 C18.7733333,2.73333333 16.2133333,2.41333333 13.5466667,2.89333333 C8.12,3.86666667 3.78666667,8.24 2.86666667,13.6666667 C1.34666667,22.6666667 8.84,30.3733333 17.7866667,29.2133333 C23.0666667,28.5333333 27.4933333,24.6 28.88,19.4666667 C29.4133333,17.5066667 29.4666667,15.6133333 29.16,13.84 C28.9866667,12.7733333 27.6666667,12.36 26.8933333,13.12 L26.8933333,13.12 C26.5866667,13.4266667 26.4533333,13.88 26.5333333,14.3066667 C26.8266667,16.08 26.6933333,17.9733333 25.84,19.9866667 C24.2933333,23.6 20.9333333,26.2533333 17.0266667,26.6133333 C10.2266667,27.24 4.58666667,21.48 5.42666667,14.64 C6,9.92 9.8,6.08 14.5066667,5.42666667 C16.8133333,5.10666667 19,5.54666667 20.8666667,6.50666667 C21.3866667,6.77333333 22.0133333,6.68 22.4266667,6.26666667 L22.4266667,6.26666667 C23.0666667,5.62666667 22.9066667,4.54666667 22.1066667,4.13333333 C21.7466667,3.97333333 21.3866667,3.8 21.0266667,3.65333333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
