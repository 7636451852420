import { makeStyles, Theme } from '@material-ui/core';

interface useInputStylesProps {
	withLabel: boolean;
}

export const useInputStyles = makeStyles<Theme, useInputStylesProps>(
	({ palette: { colorScheme }, typography: { smallViewPort } }) => ({
		root: {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: colorScheme.colorVariables.colorBorder,
			transition: 'all 0.8s',
			outline: '2px solid transparent',
			'&.Mui-focused': {
				transition: 'all 0.1s',
			},
			'&:hover': {
				transition: 'all 0.1s',
				borderColor: colorScheme.colorVariables.colorNote,
			},
			'& legend': {
				visibility: 'visible',
				...smallViewPort.typeCaption1,
				letterSpacing: 0.2,
				fontSize: 12,
				maxWidth: 'initial',
				position: 'absolute',
				top: 10,
				left: 11,
			},
			'& fieldset': {
				top: 0,
			},
			'&:has(input:disabled)': {
				borderColor: colorScheme.colorVariables.colorBorder,
			},
			...smallViewPort.typeInput1,
		},
		input: {
			padding: (props) => `${props.withLabel ? 28 : 16}px 16px 10px`,
			height: 'initial',
		},
		error: {
			border: `1px solid ${colorScheme.colorVariables.colorSysTextAlert}`,
			'&:hover': {
				border: `1px solid ${colorScheme.colorVariables.colorSysTextAlert}`,
			},
		},
	}),
);
