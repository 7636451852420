import { makeStyles } from '@material-ui/core';

import { CARD_MAX_WIDTH } from '../../../../utils/sizes.utils';

export const useLiveChatGreetingCardStyles = makeStyles(
	({
		typography: { smallViewPort },
		palette: {
			sizes,
			borderRadius,
			boxShadow,
			colorScheme: { colorVariables },
		},
	}) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			maxWidth: CARD_MAX_WIDTH,
			margin: '0 auto',
			padding: `0 0 ${sizes.size_2}px`,
			borderRadius: borderRadius.card,
			boxShadow: boxShadow.card,
			backgroundColor: 'white',
			overflow: 'hidden',
		},
		actionButton: {
			margin: `${sizes.size_4}px 0 ${sizes.size_1}px`,
		},
		welcomeTitle: {
			textAlign: 'center',
			...smallViewPort.typeHeading1,
			margin: `${sizes.size_3}px 0 0`,
		},
		content: {
			width: '100%',
		},
		greetingRoot: {
			border: `1px solid ${colorVariables.colorDarkShadow}`,
			padding: `${sizes.size_5}px ${sizes.size_2}px ${sizes.size_3}px`,
			'& > img': {
				height: sizes.size_10,
			},
		},
		welcomeRoot: {
			padding: `${sizes.size_5}px ${sizes.size_7}px ${sizes.size_6}px`,
			'& > img': {
				height: sizes.size_8,
			},
		},
		description: {
			paddingTop: sizes.size_1,
			textAlign: 'center',
			...smallViewPort.typeCaption1,
			marginBottom: 0,
		},
	}),
);
