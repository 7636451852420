import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LoadingIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<path
				d={
					'M19.4649488,3.06946246 C20.179092,3.26081406 20.6028964,3.99485506 20.4115423,4.70898893 C20.2201882,5.4231228 19.4861375,5.84692156 18.7719942,5.65556997 C13.0588481,4.12475722 7.18644291,7.51514738 5.65561003,13.2282183 C4.12477715,18.9412893 7.51521189,24.8136173 13.228358,26.34443 C18.9415041,27.8752428 24.8139093,24.4848526 26.3447422,18.7717817 C26.9838488,16.3866347 26.7808167,13.9120379 25.8085341,11.6990647 C25.5111438,11.0221867 25.8187866,10.2323892 26.4956736,9.93500283 C27.1725606,9.63761642 27.9623684,9.94525521 28.2597587,10.6221333 C29.4748029,13.3876463 29.7290191,16.4860846 28.9308837,19.4647271 C27.0173426,26.6060658 19.6768361,30.8440535 12.5354035,28.9305375 C5.39397084,27.0170216 1.15592741,19.6766116 3.06946851,12.5352729 C4.98300961,5.39393422 12.3235162,1.15594653 19.4649488,3.06946246 Z'
				}
				fill={'currentColor'}
				fillRule={'nonzero'}
			/>
		</g>
	</SvgIcon>
);
