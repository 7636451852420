import { FC, memo } from 'react';

import { Icon } from '../../../ui-kit/icon/icon.component';
import { ArrowRightIcon } from '../../../ui-kit/icons/arrow-right.icon';
import { useAppointmentButtonStyles } from './appointment-button.styles';

interface AppointmentButtonProps {
	title: string;
	description: string;
	onClick(): void;
}

export const AppointmentButton: FC<AppointmentButtonProps> = memo(({ title, description, onClick }) => {
	const classes = useAppointmentButtonStyles();

	return (
		<button className={classes.button} onClick={onClick} data-testing-label={`appointment-button-${description}`}>
			<div className={classes.titleContainer}>
				<span className={classes.title}>{title}</span>
			</div>
			<span className={classes.description}>{description}</span>
			<Icon
				dataTestingLabel={'appointment-button'}
				icon={ArrowRightIcon}
				size={'small'}
				iconType={'colorBorder'}
				alt={'select appointment icon'}
				className={classes.arrowContainer}
				viewBox={'0 0 20 20'}
			/>
		</button>
	);
});
