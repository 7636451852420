import { createContext, FC, ReactNode, useContext } from 'react';

import { api, apiWithLogger } from '../../api/axiosClient';
import { ClientMessage } from '../../models/socket.model';
import { validateHistory } from '../../utils/message-parser';
import { SessionContext } from '../session.context';
import {
	AppConfigResponseModel,
	CancelAppointmentApi,
	ChangePasswordApi,
	ConversationResponseModel,
	CreateAccountApi,
	ErrorLogData,
	GetAppointmentAvailableDatesApi,
	GetAutocompleteSuggestionsApi,
	GetBlockItSchedulingSettingsApi,
	GetCollapsedWidgetConfigApi,
	GetConfigApi,
	GetConversationApi,
	GetConversationPayload,
	GetCTAButtonConfigApi,
	GetDismissButtonConfigApi,
	GetEpicSchedulingIframeUrlApi,
	GetFutureAppointmentsApi,
	GetLiveChatConfigApi,
	GetMinimizeButtonConfigApi,
	GetProvidersApi,
	GetTranslationsApi,
	GyantEventPayload,
	LogEventApi,
	SendEventApi,
	SendMessageAsyncApi,
	SyncMessageResponseModel,
	UpdateProfileApi,
	UploadImagesApi,
} from './api-service.model';

export interface APIContext {
	getConfig: GetConfigApi;
	createAccount: CreateAccountApi;
	updateProfile: UpdateProfileApi;
	getConversations: GetConversationApi;
	sendMessageAsync: SendMessageAsyncApi;
	getAutocompleteSuggestions: GetAutocompleteSuggestionsApi;
	getCollapsedWidgetConfig: GetCollapsedWidgetConfigApi;
	getMinimizeButtonConfig: GetMinimizeButtonConfigApi;
	getDismissButtonConfig: GetDismissButtonConfigApi;
	getCTAButtonConfig: GetCTAButtonConfigApi;
	getLiveChatConfig: GetLiveChatConfigApi;
	getAppointmentAvailableDates: GetAppointmentAvailableDatesApi;
	getFutureAppointments: GetFutureAppointmentsApi;
	cancelAppointment: CancelAppointmentApi;
	getEpicSchedulingIframeUrl: GetEpicSchedulingIframeUrlApi;
	getBlockItSchedulingSettings: GetBlockItSchedulingSettingsApi;
	sendEvent: SendEventApi;
	sendAnonymousEvent: SendEventApi;
	logEvent: LogEventApi;
	uploadImages: UploadImagesApi;
	getProvidersApi: GetProvidersApi;
	changePasswordApi: ChangePasswordApi;
	getTranslations: GetTranslationsApi;
}

const CLIENT_NOT_FOUND_ERROR = 'No client found!';
const DEFAULT_API_CONTEXT = {} as APIContext;

const handleAddSessionToken = function (sessionToken?: string) {
	return function <T extends Record<string, any>>(payload: T): T & { sessionToken?: string } {
		if ('sessionToken' in payload || !sessionToken) {
			return payload;
		}

		return {
			...payload,
			sessionToken,
		};
	};
};

export const APIServiceContext = createContext<APIContext>(DEFAULT_API_CONTEXT);

interface APIProviderProps {
	children: ReactNode;
}

export const APIServiceProvider: FC<APIProviderProps> = ({ children }) => {
	const {
		state: { sessionToken },
	} = useContext(SessionContext);

	const addSessionToken = handleAddSessionToken(sessionToken);
	const addSessionTokenSearchParameter = (url: string): string => {
		const sessionTokenParam = `${!url.includes('?') ? '?' : url.endsWith('&') ? '' : '&'}sessionToken=`;
		return sessionToken ? `${url}${sessionTokenParam}${sessionToken}` : url;
	};

	const getConfig: GetConfigApi = (client) =>
		apiWithLogger({ url: addSessionTokenSearchParameter(`/api/getConfig/${client}`) }).then((data) => {
			// TODO: fix on BE side - in case of client not found we need to return 404 error
			if (data === CLIENT_NOT_FOUND_ERROR) {
				return null;
			} else {
				return data as AppConfigResponseModel;
			}
		});

	const createAccount: CreateAccountApi = (data) => api({ url: '/api/create-account', method: 'POST', data });

	const updateProfile: UpdateProfileApi = (data) =>
		apiWithLogger({ url: '/api/update-profile', method: 'POST', data });

	const getConversations: GetConversationApi = (payload) =>
		apiWithLogger<GetConversationPayload, ConversationResponseModel | any[]>({
			url: '/api/get-conversation',
			method: 'POST',
			data: addSessionToken(payload),
		}).then((response) => (Array.isArray(response) ? null : validateHistory(response)));

	const sendMessageAsync: SendMessageAsyncApi = (payload) =>
		apiWithLogger<ClientMessage, SyncMessageResponseModel>({
			url: '/api/app/send-message-sync',
			method: 'POST',
			data: addSessionToken(payload),
		}).then((response) => response.messages || []);

	const getAutocompleteSuggestions: GetAutocompleteSuggestionsApi = (uri) =>
		apiWithLogger({ url: `/api${uri}&token=${sessionToken}` });

	const getCollapsedWidgetConfig: GetCollapsedWidgetConfigApi = ({ locale, client, pathname }) =>
		apiWithLogger({
			url: addSessionTokenSearchParameter(
				`/api/ui-components/collapsed-widget/get-config/${locale}/${client}?path=${pathname}`,
			),
		});

	const getMinimizeButtonConfig: GetMinimizeButtonConfigApi = (client) =>
		apiWithLogger({
			url: addSessionTokenSearchParameter(`/api/ui-components/minimize-button/get-config/${client}`),
		});

	const getDismissButtonConfig: GetDismissButtonConfigApi = (client) =>
		apiWithLogger({
			url: addSessionTokenSearchParameter(`/api/ui-components/dismiss-button/get-config/${client}`),
		});

	const getCTAButtonConfig: GetCTAButtonConfigApi = (client) =>
		apiWithLogger({
			url: addSessionTokenSearchParameter(`/api/ui-components/call-to-action-button/get-config/${client}`),
		});

	const getLiveChatConfig: GetLiveChatConfigApi = ({ client, locale }) =>
		apiWithLogger({ url: `/api/ui-components/live-chat/get-config/${locale}/${client}/${sessionToken}` });

	const getAppointmentAvailableDates: GetAppointmentAvailableDatesApi = ({ startDate, endDate, data }) => {
		const dataEntries = Object.entries(data);
		const dataParameters = dataEntries.reduce(
			(acc, [key, value], index) => `${acc}${key}=${value}${index !== dataEntries.length - 1 ? '&' : ''}`,
			'&',
		);
		return apiWithLogger({
			url: addSessionTokenSearchParameter(
				`/api/ehr/slots?startDate=${startDate}&endDate=${endDate}${dataParameters}`,
			),
		});
	};

	const getFutureAppointments: GetFutureAppointmentsApi = () =>
		apiWithLogger({ url: addSessionTokenSearchParameter('/api/ehr/appointments/future') });

	const cancelAppointment: CancelAppointmentApi = ({ cancelData }) =>
		apiWithLogger({
			url: addSessionTokenSearchParameter('/api/ehr/appointments/cancel'),
			method: 'POST',
			data: cancelData,
		});

	const getEpicSchedulingIframeUrl: GetEpicSchedulingIframeUrlApi = (payload) =>
		apiWithLogger({ url: `/api/epic-scheduling/${payload.type}/${payload.clientName}${payload.params}` });

	const getBlockItSchedulingSettings: GetBlockItSchedulingSettingsApi = (payload) =>
		apiWithLogger({ url: `/api/blockit-scheduling/${payload.clientName}/${payload.clientUserId}` });

	const sendEvent: SendEventApi = (payload) =>
		apiWithLogger<GyantEventPayload, void>({
			url: '/api/event',
			method: 'POST',
			data: addSessionToken(payload),
		}).catch(() => {
			console.error('Error while sending event');
		});

	const sendAnonymousEvent: SendEventApi = (payload) =>
		apiWithLogger<GyantEventPayload, void>({
			url: '/api/event-anonymous',
			method: 'POST',
			data: addSessionToken(payload),
		}).catch(() => {
			console.error('Error while sending anonymous event');
		});

	const logEvent: LogEventApi = (payload) => {
		const logPayload = {
			origin: payload.origin,
			error: JSON.stringify(payload.error),
			payload: JSON.stringify(payload.payload),
		};
		return apiWithLogger<ErrorLogData, void>({
			url: '/api/log',
			method: 'POST',
			data: addSessionToken(logPayload),
		}).catch(() => {
			console.error('Error while logging the error event');
		});
	};

	const uploadImages: UploadImagesApi = ({ formData, cancelToken, onUploadProgress }) =>
		apiWithLogger({
			url: addSessionTokenSearchParameter('/api/file/upload/image'),
			method: 'POST',
			data: formData,
			configs: {
				onUploadProgress,
				cancelToken,
			},
		});

	const getProvidersApi: GetProvidersApi = async (query) => {
		const url = addSessionTokenSearchParameter(`/api/ehr/providers?${query}`);
		return await apiWithLogger({ url });
	};

	const changePasswordApi: ChangePasswordApi = (password) => {
		const url = addSessionTokenSearchParameter('/api/ehr/patients/accounts/credentials');
		return apiWithLogger({
			url,
			method: 'PUT',
			data: {
				password,
			},
		});
	};

	const getTranslations: GetTranslationsApi = async (client, locale) => {
		const url = addSessionTokenSearchParameter(`/api/localization/${client}/${locale}`);
		return await apiWithLogger({ url });
	};

	const apiService: APIContext = {
		getConfig,
		createAccount,
		updateProfile,
		getConversations,
		sendMessageAsync,
		getAutocompleteSuggestions,
		getCollapsedWidgetConfig,
		getMinimizeButtonConfig,
		getDismissButtonConfig,
		getCTAButtonConfig,
		getLiveChatConfig,
		getAppointmentAvailableDates,
		getFutureAppointments,
		cancelAppointment,
		getEpicSchedulingIframeUrl,
		getBlockItSchedulingSettings,
		sendEvent,
		sendAnonymousEvent,
		logEvent,
		uploadImages,
		getProvidersApi,
		changePasswordApi,
		getTranslations,
	};

	return <APIServiceContext.Provider value={apiService}>{children}</APIServiceContext.Provider>;
};
