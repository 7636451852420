import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../../../models/dimensions.model';

export const useCareVenueCardStyles = makeStyles<Theme>(
	({ palette: { sizes, borderRadius }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			height: '100%',
			...smallViewPort.typeCaption1,
		},
		content: {
			padding: `${sizes.size_2}px ${sizes.size_2}px 0`,
		},
		title: {
			margin: 0,
			...smallViewPort.typeHeading2,
			padding: 0,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading2,
				padding: 0,
			},
		},
		address: {
			paddingBottom: sizes.size_1,
		},
		contacts: {
			display: 'flex',
			flexDirection: 'column',
		},
		openHours: {
			display: 'inline-flex',

			'& svg': {
				marginRight: sizes.sizeHalf,
			},
		},
		infoLine: {
			paddingBottom: sizes.sizeHalf,
		},
		withSeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 3px',
			},
		},
		actionsRoot: {
			padding: `${sizes.size_1}px ${sizes.size_2}px`,
			display: 'flex',
			flexDirection: 'column',
		},
		additionalActionLink: {
			flex: 1,
			marginTop: sizes.size_1,
		},
		actionLinkLabel: {
			paddingLeft: sizes.size_1,
		},
		actionButton: {
			'&:not(:last-child)': {
				marginBottom: sizes.size_1,
			},
		},
		image: {
			flex: 0,
			display: 'block',
			width: '100%',
			borderTopLeftRadius: borderRadius.card,
			borderTopRightRadius: borderRadius.card,
		},
	}),
);
