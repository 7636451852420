import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const GalleryIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 -960 960 960'} {...props}>
		<path
			d={
				'm506-413-56-72q-5-5-12-5t-12 6l-63 83q-5 7-1.104 15.5T375-377h331q9 0 13.5-8t-1.5-16l-97-131q-4.615-6-12.308-6Q601-538 597-532l-91 119ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T140-730v590h590q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q760-97 751.375-88.5T730-80H140Zm120-740v560-560Z'
			}
		/>
	</SvgIcon>
);
