import { makeStyles } from '@material-ui/core';

export const usePasswordResetStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
			borderRadius,
		},
		typography: { smallViewPort },
	}) => ({
		list: {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
		},
		listItem: {
			display: 'flex',
			alignItems: 'center',
		},
		actions: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: sizes.size_3,
		},
		mainAction: {
			marginBottom: sizes.size_1,
		},
		validationRoot: {
			marginTop: sizes.sizeHalf,
			padding: `${sizes.size_1}px ${sizes.size_2}px`,
			backgroundColor: colorVariables.colorSysBgDefault,
			borderRadius: borderRadius.button,
		},
		validationtitle: {
			margin: `0 0 ${sizes.sizeHalf}px`,
			...smallViewPort.typeSysHeading2,
		},
		validationIcon: {
			marginRight: sizes.sizeHalf,
		},
		confirmPasswordInput: {
			marginTop: sizes.size_2,
		},
		generalError: {
			...smallViewPort.typeSysText2,
			borderRadius: borderRadius.button,
			color: colorVariables.colorSysTextAlert,
			backgroundColor: colorVariables.colorSysBgAlert,
			padding: `${sizes.size_1}px ${sizes.size_2}px`,
			width: '100%',
			display: 'block',
			marginTop: sizes.sizeHalf,
		},
	}),
);
