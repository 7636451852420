import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const CalendarUnavailableIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32px'} height={'32px'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 32 0 32 32 0 32'}></polygon>
				<path
					d={
						'M13.12,21.96 L15.6666667,19.4133333 L18.2133333,21.96 C18.6,22.3466667 19.24,22.3466667 19.6266667,21.96 C20.0133333,21.5733333 20.0133333,20.9333333 19.6266667,20.5466667 L17.08,18 L19.6266667,15.4533333 C20.0133333,15.0666667 20.0133333,14.4266667 19.6266667,14.04 C19.24,13.6533333 18.6,13.6533333 18.2133333,14.04 L15.6666667,16.5866667 L13.12,14.04 C12.7333333,13.6533333 12.0933333,13.6533333 11.7066667,14.04 C11.32,14.4266667 11.32,15.0666667 11.7066667,15.4533333 L14.2533333,18 L11.7066667,20.5466667 C11.32,20.9333333 11.32,21.5733333 11.7066667,21.96 C12.0933333,22.3466667 12.7333333,22.3466667 13.12,21.96 Z M25.3333333,4 L24,4 L24,2.66666667 C24,1.93333333 23.4,1.33333333 22.6666667,1.33333333 C21.9333333,1.33333333 21.3333333,1.93333333 21.3333333,2.66666667 L21.3333333,4 L10.6666667,4 L10.6666667,2.66666667 C10.6666667,1.93333333 10.0666667,1.33333333 9.33333333,1.33333333 C8.6,1.33333333 8,1.93333333 8,2.66666667 L8,4 L6.66666667,4 C5.18666667,4 4.01333333,5.2 4.01333333,6.66666667 L4,25.3333333 C4,26.8 5.18666667,28 6.66666667,28 L25.3333333,28 C26.8,28 28,26.8 28,25.3333333 L28,6.66666667 C28,5.2 26.8,4 25.3333333,4 Z M24,25.3333333 L8,25.3333333 C7.26666667,25.3333333 6.66666667,24.7333333 6.66666667,24 L6.66666667,10.6666667 L25.3333333,10.6666667 L25.3333333,24 C25.3333333,24.7333333 24.7333333,25.3333333 24,25.3333333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
