import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PersonOffIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<rect id={'Rectangle'} x={'0'} y={'0'} width={'20'} height={'20'}></rect>
				<path
					d={
						'M7.20833333,4.85 C7.8,3.93333333 8.83333333,3.33333333 10,3.33333333 C11.8416667,3.33333333 13.3333333,4.825 13.3333333,6.66666667 C13.3333333,7.83333333 12.7333333,8.86666667 11.8166667,9.45833333 L7.20833333,4.85 Z M16.6666667,14.3083333 C16.65,13.3916667 16.1416667,12.55 15.325,12.125 C14.875,11.8916667 14.3833333,11.675 13.85,11.4916667 L16.6666667,14.3083333 Z M17.075,17.075 L2.925,2.925 C2.6,2.6 2.075,2.6 1.75,2.925 L1.75,2.925 C1.425,3.25 1.425,3.775 1.75,4.1 L8.56666667,10.9166667 C7.05,11.1083333 5.725,11.5833333 4.65,12.1333333 C3.83333333,12.5666667 3.33333333,13.425 3.33333333,14.35 L3.33333333,16.6666667 L14.3083333,16.6666667 L15.8916667,18.25 C16.2166667,18.575 16.7416667,18.575 17.0666667,18.25 L17.0666667,18.25 C17.4,17.925 17.4,17.4 17.075,17.075 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
