import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useLocationFinderIndicatorStyles = makeStyles<Theme>(
	({
		palette: {
			borderRadius,
			boxShadow,
			colorScheme: { colorVariables },
		},
	}) => ({
		root: {
			width: '100%',
			margin: '0 8px',
			maxWidth: 320,
			borderRadius: borderRadius.card,
			boxShadow: boxShadow.card,
			border: `1px solid ${colorVariables.colorMediumShadow}`,
			alignSelf: 'center',
			overflow: 'hidden',
		},
		findingIndicator: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: 200,
			backgroundColor: colorVariables.colorInactive,
			borderBottom: `1px solid ${colorVariables.colorMediumShadow}`,
		},
		label: {
			height: 120,
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 14,
			color: colorVariables.colorNote,
		},
	}),
);
