import classNames from 'classnames';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { LiveChatStatus } from '../../../../models/live-chat.model';
import { Button } from '../../../../ui-kit/button/button.component';
import { Chip } from '../../../../ui-kit/chip/chip.component';
import { Icon } from '../../../../ui-kit/icon/icon.component';
import { AgentAvatarIcon } from '../../../../ui-kit/icons/agent-avatar.icon';
import { ArrowRightIcon } from '../../../../ui-kit/icons/arrow-right.icon';
import { LeaveIcon } from '../../../../ui-kit/icons/leave.icon';
import { LoadingIndicator } from '../../../../ui-kit/loading-indicator/loading-indicator.component';
import { Lazy } from '../../../../utils/function.utils';
import { Nullable } from '../../../../utils/types.utils';
import { useLiveChatStatusControlStyles } from './status-control.styles';

export type StatusControlMenuActionType = 'LEAVE_CHAT' | 'LEAVE_QUEUE';

interface LiveChatAgentData {
	imageUrl?: string;
	name: string;
	role?: string;
	isConnected: boolean;
}
export interface LiveChatAction {
	label: string;
	onAction: Lazy<void>;
	type: StatusControlMenuActionType;
}
export interface StatusControlProps {
	status: LiveChatStatus;
	agentData: LiveChatAgentData;
	action: Nullable<LiveChatAction>;
	className?: string;
	waitingMessage: string;
	hasNotifications?: boolean;
	onControlClick?: Lazy<void>;
}

export const LiveChatStatusControl: FC<StatusControlProps> = ({
	status,
	action,
	agentData,
	onControlClick,
	hasNotifications,
	className,
	waitingMessage,
}) => {
	const { t } = useTranslation();

	const classes = useLiveChatStatusControlStyles({ isConnected: agentData.isConnected });
	const rootClassName = classNames(classes.root, hasNotifications && classes.hasNotifications, className);
	const buttonRootClassName = classNames(
		classes.root,
		hasNotifications && classes.hasNotifications,
		classes.buttonRoot,
		className,
	);

	const renderWaitingStatus = (message: string) => (
		<Fragment>
			<LoadingIndicator size={'large'} color={'colorBorder'} />
			<span className={classes.loadingMessage}>{message}</span>
		</Fragment>
	);

	const renderActiveStatus = () => (
		<Fragment>
			<div className={classNames(classes.agentImageWrapper, onControlClick && classes.buttonAgentImageWrapper)}>
				{agentData.imageUrl ? (
					<div
						className={classes.image}
						style={{
							backgroundImage: `url(${agentData.imageUrl})`,
						}}
					/>
				) : (
					<Icon icon={AgentAvatarIcon} size={'xLarge'} viewBox={'0 0 48 48'} />
				)}
			</div>
			<dl
				className={classNames(
					classes.agentData,
					classes.oveflowEllipsis,
					onControlClick && classes.buttonAgentData,
				)}>
				<dt className={classes.oveflowEllipsis}>{agentData.name}</dt>
				<dd className={classes.oveflowEllipsis}>{agentData.role}</dd>
			</dl>
		</Fragment>
	);
	const isWaiting = status === 'WAITING' || status === 'TRANSFERING';
	const message =
		status === 'WAITING' ? waitingMessage : t('liveChatTransferingToNewAgent', 'Transferring to a new agent...');

	const statusComponent = (
		<div className={classes.statusWrapper}>
			<div className={classNames(classes.transitionWrapper, isWaiting && classes.shown)}>
				{renderWaitingStatus(message)}
			</div>
			<div className={classNames(classes.transitionWrapper, !isWaiting && classes.shown)}>
				{renderActiveStatus()}
			</div>
		</div>
	);
	const renderButtonMode = () => (
		<Button
			variant={'text'}
			className={buttonRootClassName}
			onClick={onControlClick}
			data-testing-label={'status-control-button-wrapper'}>
			{statusComponent}
			<Icon icon={ArrowRightIcon} size={'small'} iconType={'colorBorder'} className={classes.arrowIcon} />
		</Button>
	);

	const renderControlMode = () => (
		<div className={rootClassName} data-testing-label={'status-control-mode-wrapper'}>
			{statusComponent}
			{action && (
				<Chip
					className={classes.leaveChatChip}
					label={action.label}
					onClick={action.onAction}
					icon={
						<Icon
							dataTestingLabel={'live-chat-leave-icon'}
							size={'large'}
							iconType={'buttonIcon'}
							icon={LeaveIcon}
							className={classes.icon}
							viewBox={'0 0 48 48'}
						/>
					}
					dataTestingLabel={'live-chat-leave-button'}
				/>
			)}
		</div>
	);

	return onControlClick ? renderButtonMode() : renderControlMode();
};
