import { makeStyles, Theme } from '@material-ui/core';

import { CARD_MAX_WIDTH } from '../../utils/sizes.utils';

export const useStepperStyles = makeStyles<Theme>(
	({
		palette: {
			colorScheme: { colorVariables },
			sizes,
			borderRadius,
		},
	}) => ({
		root: {
			justifyContent: 'space-between',
			display: 'flex',
			alignItems: 'center',
			margin: `${sizes.size_1}px auto 0`,
			maxWidth: CARD_MAX_WIDTH - 8,
			'@media (min-width: 624px)': {
				maxWidth: CARD_MAX_WIDTH - 16,
			},
			'@media (max-width: 460px)': {
				padding: `0 calc(${sizes.size_1}% + ${sizes.sizeHalf}px)`,
				width: '100%',
				maxWidth: 'unset',
			},
			'@media (max-width: 400px)': {
				maxWidth: 'unset',
				width: '100%',
				padding: `0 calc(${sizes.size_1}% + ${sizes.sizeHalf}px)`,
				margin: 0,
			},
		},
		dotsContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		dot: {
			border: 'none',
			borderRadius: '50%',
			padding: 0,
			width: sizes.size_1,
			height: sizes.size_1,
			backgroundColor: colorVariables.colorBorder,
			margin: `0 ${sizes.sizeHalf}px`,
			'&:hover': {
				cursor: 'pointer',
			},
			'&:focus-visible':{
				outline: `2px solid ${colorVariables.colorPrimary}`,
			}
		},
		active: {
			backgroundColor: colorVariables.colorCaption,
			pointerEvents: 'none',
			'&:hover': {
				cursor: 'auto',
			},
		},
		minimized: {
			width: sizes.sizeHalf,
			height: sizes.sizeHalf,
		},
		arrowLeft: {
			transform: 'rotate(90deg)',
		},
		arrowRight: {
			transform: 'rotate(-90deg)',
		},
		button: {
			minWidth: 48,
			width: 48,
			height: 40,
			padding: `${sizes.sizeHalf}px ${sizes.size_1}px`,
			borderRadius: borderRadius.button,
			'& svg': {
				fill: colorVariables.colorCaption,
			},
			'&:hover': {
				backgroundColor: colorVariables.colorPrimarySoft,
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
		hidden: { visibility: 'hidden' },
	}),
);
