import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EventsServiceContext } from '../../../context/events-service/events-service.context';
import { LinksServiceContext } from '../../../context/links-service/links-service.context';
import { MessageWithLLMResultMetadata } from '../../../models/message.model';
import { LLMLabel } from './llm-label.component';
import { useLLMLabelContainerStyles } from './llm-label.styles';

interface LLMLabelContainerProps {
	message: MessageWithLLMResultMetadata;
}
export const LLMLabelContainer = memo(({ message }: LLMLabelContainerProps) => {
	const { parseLinksInText } = useContext(LinksServiceContext);
	const { sendEvent } = useContext(EventsServiceContext);
	const { t } = useTranslation();
	const classes = useLLMLabelContainerStyles();

	const {
		llmConversationId,
		tooltipConfig: { enabled, templates },
	} = message.metadata.data;

	const handleStateChange = (tooltipState: boolean): void => {
		if (tooltipState) {
			sendEvent({ eventName: 'LLMResultTooltipClick', data: { llmConversationId } });
		}
	};

	const tooltipContent = (
		<div data-testing-label={'llm-label-tooltip'}>
			{templates?.content ? parseLinksInText(templates.content) : t('LLMTooltipDescription')}
		</div>
	);

	const label = templates?.label ? templates.label : t('LLMTooltipLabel');

	return enabled ? (
		<div className={classes.root}>
			<LLMLabel label={label} tooltipContent={tooltipContent} onStateChange={handleStateChange} />
		</div>
	) : null;
});
