import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useLoadingListMaskStyles = makeStyles<Theme>(({ palette: { sizes, borderRadius, colorScheme } }) => ({
	root: {
		flexDirection: 'column',
		justifyContent: 'center',
		display: 'flex',
		background: 'white',
	},
	item: {
		background: colorScheme.colorVariables.colorInactive,
	},
	block: {
		borderRadius: borderRadius.button,
		height: 111,
		marginBottom: sizes.size_1,
	},
	line: {
		borderRadius: 2,
		margin: `0 ${sizes.size_1}px ${sizes.size_2}px`,
	},
	blinking: {
		animation: '$blinker 1s linear infinite',
	},
	shortLine: {
		height: 13,
		width: '50%',
	},
	longLine: {
		height: 13,
		width: '80%',
	},
	biggerMargin: {
		marginBottom: sizes.size_4,
	},
	opacityMedium: {
		opacity: 0.65,
	},
	opacityLow: {
		opacity: 0.3,
	},
	'@keyframes blinker': {
		'50%': {
			opacity: 0.2,
		},
	},
}));
