import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ClockWatchIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g id={'Icons'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g id={'schedule_black_24dp'}>
				<polygon id={'Path'} points={'0 0 20 0 20 20 0 20'}></polygon>
				<path
					d={
						'M9.99166667,1.66666667 C5.39166667,1.66666667 1.66666667,5.4 1.66666667,10 C1.66666667,14.6 5.39166667,18.3333333 9.99166667,18.3333333 C14.6,18.3333333 18.3333333,14.6 18.3333333,10 C18.3333333,5.4 14.6,1.66666667 9.99166667,1.66666667 Z M10,16.6666667 C6.31666667,16.6666667 3.33333333,13.6833333 3.33333333,10 C3.33333333,6.31666667 6.31666667,3.33333333 10,3.33333333 C13.6833333,3.33333333 16.6666667,6.31666667 16.6666667,10 C16.6666667,13.6833333 13.6833333,16.6666667 10,16.6666667 Z M9.81666667,5.83333333 L9.76666667,5.83333333 C9.43333333,5.83333333 9.16666667,6.1 9.16666667,6.43333333 L9.16666667,10.3666667 C9.16666667,10.6583333 9.31666667,10.9333333 9.575,11.0833333 L13.0333333,13.1583333 C13.3166667,13.325 13.6833333,13.2416667 13.85,12.9583333 C14.025,12.675 13.9333333,12.3 13.6416667,12.1333333 L10.4166667,10.2166667 L10.4166667,6.43333333 C10.4166667,6.1 10.15,5.83333333 9.81666667,5.83333333 L9.81666667,5.83333333 Z'
					}
					id={'Shape'}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
