import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ArrowRightIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={''} width={'18'} height={'18'} {...props}>
		<path
			d={
				'M13.509 8.293a1 1 0 0 1 .078 1.327l-.078.087-6.117 6.117A1 1 0 0 1 5.9 14.497l.078-.087L11.388 9l-5.41-5.41A1 1 0 0 1 5.9 2.263l.078-.087a1 1 0 0 1 1.327-.078l.087.078 6.117 6.117Z'
			}
			fill={'#currentColor'}
			fillRule={'nonzero'}
		></path>
	</SvgIcon>
);
