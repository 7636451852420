import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const DoneIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 96 960 960'} {...props}>
		<path
			d={
				'M378 798q-6 0-11-2t-10-7L176 608q-9-9-9-22t9-22q9-9 21-9t21 9l160 160 363-363q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L399 789q-5 5-10 7t-11 2Z'
			}
		/>
	</SvgIcon>
);
