import { SendMessageAsyncApi } from '../context/api-service/api-service.model';
import { MessageFromSocket, ParsedData } from '../models/message.model';
import {
	isClientSocketMessageType,
	MessagePayload,
	parseReceivedSocketData,
	SocketStatus,
	WebSocketClient,
} from '../models/socket.model';
import { getEmbedWidgetSocketHost, getGyantEmbedHost, isEmbedWidget } from './embed.utils';
import { Effect, Lazy } from './function.utils';
import { uuid } from './string.utils';

const getSocketProtocol = (host: string) => (host.includes('http:') ? 'ws' : 'wss');

export const getSocketHost = (): string => {
	const embedSocketHost = getGyantEmbedHost(window.document);
	const protocol = getSocketProtocol(process.env.REACT_APP_GYANT_BACKEND_URL || embedSocketHost);

	const host = process.env.REACT_APP_GYANT_BACKEND_URL
		? process.env.REACT_APP_GYANT_BACKEND_URL.split('//')[1]
		: isEmbedWidget()
			? getEmbedWidgetSocketHost()
			: location.host;

	return `${protocol}://${host}/websocket/`;
};

const AUTO_RECONNECT_INTERVAL = 5 * 1000;
const LIMIT_UNRESPONDED_MESSAGES = 1;
let numberOfUnrespondedPings = 0;
let currentSocketId = '';

interface SocketOptions {
	host: string;
	token: string;
	onMessage: Effect<ParsedData<MessageFromSocket>[]>;
	onOpen?: Lazy<void>;
	onClose?: Lazy<void>;
	onError?: Lazy<void>;
	onReconnect?: Lazy<void>;
	sendMessageAsync: SendMessageAsyncApi;
}

const getStatus = (socket: WebSocket): SocketStatus => {
	switch (socket.readyState) {
		case WebSocket.OPEN:
			return 'OPEN';
		case WebSocket.CONNECTING:
			return 'CONNECTING';
		case WebSocket.CLOSED:
			return 'CLOSED';
		case WebSocket.CLOSING:
			return 'CLOSING';
		default:
			return 'CLOSED';
	}
};
type WS = WebSocket & { id: string };
const createSocketInstance = (
	options: SocketOptions,
	isReconnect: boolean,
	startKeepAlive: (s: WebSocket, onReconnect: Lazy<void>) => void,
	reconnect: Lazy<void>,
): WS => {
	const { host, token, onOpen, onMessage, onClose, onReconnect, onError } = options;
	const socket = new window.WebSocket(`${host}${token}`) as WS;
	currentSocketId = uuid();
	socket.id = currentSocketId;

	const handleReconnectWhenNoNetwork = () => {
		onClose && onClose();
		onError && onError();
		reconnect();
	};

	socket.onopen = () => {
		numberOfUnrespondedPings = 0;
		if (isReconnect) {
			onReconnect && onReconnect();
		} else {
			if (getStatus(socket) === 'OPEN') {
				onOpen && onOpen();
			}
		}

		startKeepAlive(socket, handleReconnectWhenNoNetwork);
	};

	socket.onmessage = (ev) => {
		const receivedData = JSON.parse(ev.data);
		if (receivedData[0]?.type === 'pong') {
			numberOfUnrespondedPings = 0;
			return;
		}
		if (socket.id === currentSocketId) {
			const parsedData = parseReceivedSocketData(receivedData);
			parsedData.length > 0 && onMessage(parsedData);
		}
	};

	socket.onerror = () => {
		if (socket.id === currentSocketId) {
			socket.close();
			onError && onError();
		}
	};

	socket.onclose = (event) => {
		if (socket.id === currentSocketId && event.reason !== 'disconnect') {
			onClose && onClose();
			onError && onError();
			reconnect();
		}
	};

	return socket;
};

export const createWebSocket = (options: SocketOptions): WebSocketClient => {
	let socket: WebSocket & { id: string };
	let pingInterval: NodeJS.Timeout;
	let isStarted = false;
	const { token, onMessage, onOpen, onClose, onError, sendMessageAsync } = options;

	const handleStart = () => {
		if (!isStarted) {
			isStarted = true;
			onOpen && onOpen();
		}
	};

	const send = (payload: MessagePayload) => {
		if (getStatus(socket) === 'OPEN') {
			handleStart();
			socket.send(JSON.stringify(payload));
		} else if (getStatus(socket) === 'CLOSED' && isClientSocketMessageType(payload)) {
			const asyncMessagePayoad = {
				...payload,
				sessionToken: token,
			};
			sendMessageAsync(asyncMessagePayoad).then((receivedData) => {
				const parsedData = parseReceivedSocketData(receivedData);
				parsedData.length > 0 && onMessage(parsedData);
			});
		}
	};

	const stopKeepAlive = () => {
		clearInterval(pingInterval);
	};

	const disconnect = () => {
		stopKeepAlive();
		onClose && onClose();
		socket.close(1000, 'disconnect');
	};

	const startKeepAlive = (socket: WebSocket) => {
		pingInterval = setInterval(function () {
			if (getStatus(socket) === 'OPEN') {
				handleStart();
				send({ type: 'ping' });

				numberOfUnrespondedPings++;
				if (numberOfUnrespondedPings > LIMIT_UNRESPONDED_MESSAGES) {
					disconnect();
					onError && onError();
					reconnect();
				}
			}
		}, 10000);
	};

	const reconnect = () => {
		setTimeout(() => {
			clearInterval(pingInterval);
			console.log('reconnecting from state ', socket && getStatus(socket));
			socket = createSocketInstance(options, true, startKeepAlive, reconnect);
		}, AUTO_RECONNECT_INTERVAL);
	};

	socket = createSocketInstance(options, false, startKeepAlive, reconnect);

	return {
		disconnect,
		send,
	};
};
