import { FC, memo, useContext } from 'react';

import { EventsServiceContext } from '../../../context/events-service/events-service.context';
import { MessageWithLLMResultMetadata } from '../../../models/message.model';
import { constVoid } from '../../../utils/function.utils';
import { getTrackingRating } from '../../user-survey/helfulness-survey.model';
import { LLMFeedback } from './llm-feedback.component';

interface LLMResultFeedbackContainerProps {
	message: MessageWithLLMResultMetadata;
}

export const LLMResultFeedbackContainer: FC<LLMResultFeedbackContainerProps> = memo(({ message }) => {
	const { sendEvent } = useContext(EventsServiceContext);
	const { llmConversationId } = message.metadata.data;

	const sendFeedbackWrapper = (rating: number): void =>
		sendEvent({
			eventName: 'HELPFULNESS_SURVEY',
			data: {
				elementName: getTrackingRating(rating),
				flowStep: message.flowStep || '',
				llmConversationId,
			},
		});
	// TODO: add config for the Report a problem section

	return <LLMFeedback onSurvey={sendFeedbackWrapper} onReportProblem={constVoid} />;
});
