import { constVoid } from '../../utils/function.utils';
import { LinksServiceType } from './links-service.context';

const OPEN_IN_IFRAME_PARAMETER = 'gyantIframe';
const EVENT_DATA_PARAMETER = 'gyantEventData';
const LINK_TARGET = 'gyantLinkTarget'; // _self | _parent | _blank
const SPECIAL_QUERY_PARAMETERS = [OPEN_IN_IFRAME_PARAMETER, EVENT_DATA_PARAMETER, LINK_TARGET];

export const reLinkData = /__(.*?)__/;
const reURLWithOptionalTitle =
	/(\[([^\]]+)\]\s*\()?([a-z]+:\/\/[\w-]+(\.[\w-]+)?([\w.,@?^=%&amp;:/~+()#-]*[\w@?^=%&amp;/~+#-])?)\)?({(.*)})?/g;
const rePhoneWithTitle = /(\[([^\]]+)\]\s*\()?((tel):([0-9-]*)?)\)?({(.*)})?/g;

export const isTel = (str: string): boolean => /tel:+/gi.test(str);

export const removeGyantLinkTargetFromUrl = (url: string): string => url.replace(/(%3F|\?|&|%26)gyantLinkTarget/gi, '');
export const removeGyantIgnoreTrackerFromUrl = (url: string): string =>
	url.replace(/(%3F|\?|&|%26)gyantIgnoreTracker/gi, '');
export const removeGyantIFrameParameterFromUrl = (url: string): string =>
	url.replace(/%3FgyantIframe|\?gyantIframe|&gyantIframe|%26gyantIframe|gyantIframe/gi, '');

const createLink = (m: string, g1: string, g2: string, g3: string): string => {
	const data = JSON.stringify({ url: g3, content: g1 ? g2 : g3 });
	return `__${data}__`;
};

const createPhoneLink = (m: string, g1: string, g2: string, g3: string): string => {
	const data = JSON.stringify({ url: g3, content: g1 ? g2 : g3 });
	return `__${data}__`;
};

export const getTargetLink = (url: string, globalLinkTarget?: string): string => {
	const urlObj = new URL(url);
	const searchParams = new URLSearchParams(urlObj.search);

	return searchParams.get(LINK_TARGET) || globalLinkTarget || '_blank';
};

export const removeSpecialQueryParameters = (url: string): string => {
	const urlObj = new URL(url);
	const searchParams = new URLSearchParams(urlObj.search);

	SPECIAL_QUERY_PARAMETERS.forEach((oneQueryParam) => {
		if (searchParams.has(oneQueryParam)) {
			searchParams.delete(oneQueryParam);
		}
	});
	urlObj.search = searchParams.toString();
	return urlObj.toString();
};

export const parseLinksInTextMessage = (message: string): string =>
	message.replace(reURLWithOptionalTitle, createLink).replace(rePhoneWithTitle, createPhoneLink);

export const LINKS_SERVICE_DEFAULT: LinksServiceType = {
	parseLinksInText: (text) => [text],
	openExternalLink: constVoid,
};

export const shouldOpenLinkInModal = (link: string): boolean => link.indexOf('gyantIframe') !== -1;

const decodeEventData = (eventDataString: string): Record<string, unknown> => {
	const jsonString = atob(eventDataString);
	return JSON.parse(jsonString);
};

export const getLinkEventData = (queryParams: URLSearchParams): Record<string, unknown> => {
	if (queryParams.has(EVENT_DATA_PARAMETER)) {
		const encodedEventData = queryParams.get(EVENT_DATA_PARAMETER);
		if (encodedEventData) {
			const decodedEventData = decodeEventData(encodedEventData);
			if (typeof decodedEventData === 'object') {
				return decodedEventData;
			}
			return {};
		}
		return {};
	}
	return {};
};
