import { createStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';

import { CARD_MAX_WIDTH } from '../../../utils/sizes.utils';

export const useCarouselCardStyles = ({
	palette: {
		boxShadow,
		sizes,
		borderRadius,
		colorScheme: {
			colorVariables: { colorPrimary, colorDarkShadow },
		},
	},
}: Theme): StyleRules<any, string> =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%',
			maxWidth: CARD_MAX_WIDTH,
			margin: '0 auto',
			padding: `0 0 ${sizes.size_2}px`,
			borderRadius: borderRadius.card,
			boxShadow: boxShadow.card,
			backgroundColor: 'white',
			border: `1px solid ${colorDarkShadow}`,
			overflow: 'hidden',
			'&:focus-visible': {
				outline: `2px solid ${colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		actions: {
			padding: `0 ${sizes.size_2}px`,
		},
		actionButton: {
			width: '100%',
		},
	});
