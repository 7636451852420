import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const MoreIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32'} height={'32'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 32 0 32 32 0 32'}></polygon>
				<path
					d={
						'M8,13.3333333 C6.53333333,13.3333333 5.33333333,14.5333333 5.33333333,16 C5.33333333,17.4666667 6.53333333,18.6666667 8,18.6666667 C9.46666667,18.6666667 10.6666667,17.4666667 10.6666667,16 C10.6666667,14.5333333 9.46666667,13.3333333 8,13.3333333 Z M24,13.3333333 C22.5333333,13.3333333 21.3333333,14.5333333 21.3333333,16 C21.3333333,17.4666667 22.5333333,18.6666667 24,18.6666667 C25.4666667,18.6666667 26.6666667,17.4666667 26.6666667,16 C26.6666667,14.5333333 25.4666667,13.3333333 24,13.3333333 Z M16,13.3333333 C14.5333333,13.3333333 13.3333333,14.5333333 13.3333333,16 C13.3333333,17.4666667 14.5333333,18.6666667 16,18.6666667 C17.4666667,18.6666667 18.6666667,17.4666667 18.6666667,16 C18.6666667,14.5333333 17.4666667,13.3333333 16,13.3333333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
