import { constVoid } from '../../utils/function.utils';
import { CollapsedWidgetConfigResponseModel, SendEventApi } from '../api-service/api-service.model';
import { EventsServiceType } from './events-service.context';

export type EventName =
	| 'GYANTLoaded'
	| 'onUserClickPhone'
	| 'onUserClickLink'
	| 'onUserClickCollapsedWidget'
	| 'onCollapsedWidgetShown'
	| 'HELPFULNESS_SURVEY'
	| 'HELPFULNESS_SURVEY_EXTERNAL'
	| 'CTAButtonClick'
	| 'minimizeButtonInteraction'
	| 'appointmentManagerCancelSuccess'
	| 'appointmentManagerCancelFailure'
	| 'appointmentManagerAddToCalendar'
	| 'doctorSearchGetDoctors'
	| 'doctorSearchErrorGetDoctors'
	| 'doctorSearchOpenDetails'
	| 'doctorSearchApplyFilters'
	| 'doctorSearchResetFilters'
	| 'doctorSearchEmptyResultByFilters'
	| 'doctorSearchEmptyResultInitial'
	| 'doctorSearchShowMore'
	| 'doctorSearchTryAgain'
	| 'appointmentScheduler'
	| 'LLMResultTooltipClick';

export interface EventConfig {
	eventType:
		| 'WEB_FRONTEND_LOADED'
		| 'LINK_CLICKED'
		| 'COLLAPSED_WIDGET_CLICKED'
		| 'COLLAPSED_WIDGET_SHOWN'
		| 'HELPFULNESS_SURVEY'
		| 'HELPFULNESS_SURVEY_EXTERNAL'
		| 'UI_INTERACTION'
		| 'UI_INTERACTION_AUTH';
	anonymous: boolean;
	protocol?: 'PHONE' | 'URL';
	detailFields?: string[];
	elementName?: 'CTAButton' | 'DoctorSearch' | 'LLMResult' | 'CANCEL' | 'ADD_TO_CALENDAR';
	component?: string;
	aggregatedComponentData?: string[];
	scope?: string;
	value?: string;
	components?: {
		collapsedWidget?: {
			config: CollapsedWidgetConfigResponseModel;
		};
	};
}

type EventsConfig = Record<EventName, EventConfig>;

export const ANONYMOUS_GYANT_EVENTS = {
	minimizeButtonInteraction: {
		eventType: 'UI_INTERACTION_NO_AUTH',
		anonymous: true,
	},
};

export const GYANT_EVENTS: EventsConfig = {
	GYANTLoaded: {
		eventType: 'WEB_FRONTEND_LOADED',
		anonymous: true,
		aggregatedComponentData: ['collapsedWidget'],
	},
	onUserClickPhone: {
		eventType: 'LINK_CLICKED',
		protocol: 'PHONE',
		anonymous: false,
	},
	onUserClickLink: {
		eventType: 'LINK_CLICKED',
		protocol: 'URL',
		anonymous: false,
		detailFields: ['intentMatchRequestId', 'liveChatConversationId'],
	},
	onUserClickCollapsedWidget: {
		eventType: 'COLLAPSED_WIDGET_CLICKED',
		anonymous: false,
		detailFields: ['collapsedWidgetConfig', 'clickIndex'],
	},
	onCollapsedWidgetShown: {
		eventType: 'COLLAPSED_WIDGET_SHOWN',
		anonymous: true,
		detailFields: ['collapsedWidgetConfig'],
	},
	HELPFULNESS_SURVEY: {
		eventType: 'HELPFULNESS_SURVEY',
		anonymous: false,
		detailFields: ['elementName', 'flowStep'],
	},
	HELPFULNESS_SURVEY_EXTERNAL: {
		eventType: 'HELPFULNESS_SURVEY_EXTERNAL',
		anonymous: false,
		detailFields: ['elementName', 'flowStep'],
	},
	CTAButtonClick: {
		anonymous: false,
		eventType: 'UI_INTERACTION',
		detailFields: ['elementName', 'scope'],
		elementName: 'CTAButton',
	},
	minimizeButtonInteraction: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
	},
	doctorSearchGetDoctors: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'value'],
		elementName: 'DoctorSearch',
		scope: 'Get',
	},
	doctorSearchErrorGetDoctors: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope'],
		elementName: 'DoctorSearch',
		scope: 'Error',
	},
	doctorSearchOpenDetails: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope'],
		elementName: 'DoctorSearch',
		scope: 'OpenDetails',
	},
	doctorSearchApplyFilters: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'value'],
		elementName: 'DoctorSearch',
		scope: 'ApplyFilters',
	},
	doctorSearchResetFilters: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'value'],
		elementName: 'DoctorSearch',
		scope: 'ResetFilters',
	},
	doctorSearchEmptyResultByFilters: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope'],
		elementName: 'DoctorSearch',
		scope: 'FilterReturnedNoDoctors',
	},
	doctorSearchEmptyResultInitial: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope'],
		elementName: 'DoctorSearch',
		scope: 'NoDoctorsFound',
	},
	doctorSearchShowMore: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope'],
		elementName: 'DoctorSearch',
		scope: 'ShowMore',
	},
	doctorSearchTryAgain: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'value'],
		elementName: 'DoctorSearch',
		scope: 'TryAgain',
	},
	appointmentManagerCancelSuccess: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'value', 'component'],
		component: 'APPOINTMENT_MANAGER',
		elementName: 'CANCEL',
		scope: 'Success',
	},
	appointmentManagerCancelFailure: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'value', 'component'],
		component: 'APPOINTMENT_MANAGER',
		elementName: 'CANCEL',
		scope: 'Failure',
	},
	appointmentManagerAddToCalendar: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'component'],
		component: 'APPOINTMENT_MANAGER',
		elementName: 'ADD_TO_CALENDAR',
		scope: 'AddToCalendar',
	},
	appointmentScheduler: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['component', 'elementName', 'scope', 'flowId', 'stepName'],
	},
	LLMResultTooltipClick: {
		eventType: 'UI_INTERACTION',
		anonymous: false,
		detailFields: ['elementName', 'scope', 'llmConversationId'],
		elementName: 'LLMResult',
		scope: 'TooltipClick',
	},
};

export interface CollapsedWidgetShownAction {
	eventName: 'onCollapsedWidgetShown';
	data: {
		collapsedWidgetConfig: CollapsedWidgetConfigResponseModel;
	};
}
export interface UserClickCollapsedWidgetAction {
	eventName: 'onUserClickCollapsedWidget';
	data: {
		collapsedWidgetConfig: CollapsedWidgetConfigResponseModel;
		clickIndex: number;
	};
}
type UnknownEventData = Record<string, unknown>;
export interface UserClickLinkAction {
	eventName: 'onUserClickLink';
	data: {
		link: string;
		flowStep?: string;
	} & UnknownEventData;
}
export interface UserClickPhoneAction {
	eventName: 'onUserClickPhone';
	data: {
		link: string;
		flowStep?: string;
	} & UnknownEventData;
}

export interface AggregatedEventCollapsedWidget {
	componentName: string;
	enabled: boolean;
	config: CollapsedWidgetConfigResponseModel;
}

export interface GYANTLoadedActionData {
	loadingTime?: number;
	components?: {
		// We can extend data with more upcoming components for aggregated events
		collapsedWidget?: AggregatedEventCollapsedWidget;
	};
}
interface GYANTLoadedAction {
	eventName: 'GYANTLoaded';
	data?: GYANTLoadedActionData;
}

interface UIInteractionActionData {
	flowId?: string;
	stepName?: string;
	component?: string;
	scope?: string;
	elementName?: string;
	value?: Record<string, unknown>;
	caseId?: string;
	clientOrganization?: string;
	currentUrl?: string;
	flowStep?: string;
	llmConversationId?: string;
}

interface HelpfulnessSurveyAction {
	eventName: 'HELPFULNESS_SURVEY';
	data?: UIInteractionActionData;
}

interface HelpfulnessSurveyExternalAction {
	eventName: 'HELPFULNESS_SURVEY_EXTERNAL';
	data: UIInteractionActionData;
}
interface AppointmentSchedulerAction {
	eventName: 'appointmentScheduler';
	data: UIInteractionActionData;
}

interface CTAButtonClick {
	eventName: 'CTAButtonClick';
	data: {
		scope: string;
	};
}
interface MinimizeButtonInteraction {
	eventName: 'minimizeButtonInteraction';
	data: {
		elementName: 'Minimize' | 'Expand';
	};
}

interface AppointmentManagerCancelData {
	value: {
		status: string;
		reason: string;
	};
}

interface DoctorSearchGetDoctorsActionData {
	value: {
		searchTime: number;
	};
}
interface DoctorSearchFiltersActionData {
	value: {
		filters: unknown;
	};
}

interface DoctorSearchGetDoctorsAction {
	eventName: 'doctorSearchGetDoctors';
	data: DoctorSearchGetDoctorsActionData;
}
interface DoctorSearchGetDoctorsErrorAction {
	eventName: 'doctorSearchErrorGetDoctors';
}
interface DoctorSearchOpenDetailsAction {
	eventName: 'doctorSearchOpenDetails';
}
interface DoctorSearchEmptyResultsByFiltersAction {
	eventName: 'doctorSearchEmptyResultByFilters';
}
interface DoctorSearchEmptyResultsInitialAction {
	eventName: 'doctorSearchEmptyResultInitial';
}
interface DoctorSearchApplyFiltersAction {
	eventName: 'doctorSearchApplyFilters';
	data: DoctorSearchFiltersActionData;
}
interface DoctorSearchResetFiltersAction {
	eventName: 'doctorSearchResetFilters';
	data: DoctorSearchFiltersActionData;
}

interface DoctorSearchShowMoreAction {
	eventName: 'doctorSearchShowMore';
}
interface DoctorSearchTryAgainAction {
	eventName: 'doctorSearchTryAgain';
	data: DoctorSearchFiltersActionData;
}
interface AppointmentManagerCancelSuccessAction {
	eventName: 'appointmentManagerCancelSuccess';
	data: AppointmentManagerCancelData;
}
interface AppointmentManagerCancelFailureAction {
	eventName: 'appointmentManagerCancelFailure';
	data: AppointmentManagerCancelData;
}
interface AppointmentManagerAddToCalendarAction {
	eventName: 'appointmentManagerAddToCalendar';
}
interface LLMResultTooltipClickAction {
	eventName: 'LLMResultTooltipClick';
	data: UIInteractionActionData;
}

export type EventAction =
	| CollapsedWidgetShownAction
	| UserClickCollapsedWidgetAction
	| UserClickLinkAction
	| UserClickPhoneAction
	| GYANTLoadedAction
	| HelpfulnessSurveyAction
	| HelpfulnessSurveyExternalAction
	| AppointmentSchedulerAction
	| CTAButtonClick
	| MinimizeButtonInteraction
	| AppointmentManagerCancelSuccessAction
	| AppointmentManagerCancelFailureAction
	| AppointmentManagerAddToCalendarAction
	| DoctorSearchGetDoctorsAction
	| DoctorSearchGetDoctorsErrorAction
	| DoctorSearchOpenDetailsAction
	| DoctorSearchEmptyResultsByFiltersAction
	| DoctorSearchEmptyResultsInitialAction
	| DoctorSearchApplyFiltersAction
	| DoctorSearchResetFiltersAction
	| DoctorSearchShowMoreAction
	| DoctorSearchTryAgainAction
	| LLMResultTooltipClickAction;

export const EVENTS_SERVICE_DEFAULT: EventsServiceType = {
	sendEvent: constVoid,
};

export const isNotAuthenticatedMinimizeButtonAction = (action: EventAction, sessionToken?: string): boolean =>
	!sessionToken && action.eventName === 'minimizeButtonInteraction';

export const sendEventServiceAction = (
	action: EventAction,
	clientOrganization: string,
	sendEventApi: SendEventApi,
	sendAnonymousEventApi: SendEventApi,
	sessionToken?: string,
): void => {
	const eventConfig = isNotAuthenticatedMinimizeButtonAction(action, sessionToken)
		? ANONYMOUS_GYANT_EVENTS[action.eventName]
		: GYANT_EVENTS[action.eventName];

	const actionData = 'data' in action ? action.data : {};

	const data = {
		clientOrganization,
		currentUrl: location.href,
		...eventConfig,
		...actionData,
	};

	const payload = {
		sessionToken,
		eventData: JSON.stringify(data),
	};

	const api = eventConfig.anonymous ? sendAnonymousEventApi : sendEventApi;

	api(payload)
		.then((res) => console.log('Event sent to Gyant.', res))
		.catch((err) => console.log('Failed while sending event to GYANT.', err));
};
