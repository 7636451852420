import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const NotesIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} width={'20'} height={'20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 20 0 20 20 0 20'}></polygon>
				<path
					d={
						'M16.6666667,9.16666667 L3.33333333,9.16666667 C2.875,9.16666667 2.5,9.54166667 2.5,10 C2.5,10.4583333 2.875,10.8333333 3.33333333,10.8333333 L16.6666667,10.8333333 C17.125,10.8333333 17.5,10.4583333 17.5,10 C17.5,9.54166667 17.125,9.16666667 16.6666667,9.16666667 Z M3.33333333,15 L11.6666667,15 C12.125,15 12.5,14.625 12.5,14.1666667 C12.5,13.7083333 12.125,13.3333333 11.6666667,13.3333333 L3.33333333,13.3333333 C2.875,13.3333333 2.5,13.7083333 2.5,14.1666667 C2.5,14.625 2.875,15 3.33333333,15 Z M16.6666667,5 L3.33333333,5 C2.875,5 2.5,5.375 2.5,5.83333333 L2.5,5.84166667 C2.5,6.3 2.875,6.675 3.33333333,6.675 L16.6666667,6.675 C17.125,6.675 17.5,6.3 17.5,5.84166667 L17.5,5.83333333 C17.5,5.375 17.125,5 16.6666667,5 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}></path>
			</g>
		</g>
	</SvgIcon>
);
