import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Z_INDEX_TOP } from '../../utils/sizes.utils';

export const useCollapsedWidgetButtonStyles = makeStyles({
	transitionEnter: {
		top: 5,
		opacity: 0,
	},
	transitionEnterActive: {
		top: 0,
		opacity: 1,
	},
	transitionExit: {
		top: 0,
		opacity: 0,
	},
	transitionExitActive: {
		top: 0,
		opacity: 0,
		pointerEvents: 'none',
	},
});

export const useCollapsedWidgetWrapperStyles = makeStyles<Theme>(
	({
		palette: {
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		root: {
			display: 'flex',
			alignItems: 'flex-end',
			flexDirection: 'column',
			width: 250,
			opacity: 1,
			visibility: 'visible',
			zIndex: 2,
			maxHeight: 0,
			transition: noAnimationTransition ? 'initial' : 'max-height 2.5s',
		},
		shown: {
			maxHeight: 500,
		},
		hidden: {
			maxHeight: 0,
			pointerEvents: 'none',
			cursor: 'default',
			visibility: 'hidden',
		},
		noPointerEvents: {
			pointerEvents: 'none',
		},
	}),
);

export const useTooltipStyles = makeStyles<Theme>(
	({
		palette: {
			colorScheme,
			sizes,
			boxShadow,
			borderRadius,
			transitionConfig: { noAnimationTransition },
		},
		typography: { smallViewPort },
	}) => ({
		collapsedWidgetContainer: {
			zIndex: Z_INDEX_TOP,
			display: 'flex',
			flexDirection: 'column',
			position: 'fixed',
			right: sizes.size_2,
			width: 280,
			alignItems: 'flex-end',
		},
		short: {
			bottom: 84,
			...colorScheme.customStyle.tooltip?.container,
		},
		tall: {
			bottom: 104,
			...colorScheme.customStyle.tooltip?.container,
		},
		widthAuto: {
			width: 'auto',
		},
		tooltip: {
			marginTop: sizes.size_1,
			maxWidth: 280,
			borderRadius: borderRadius.button,
			boxShadow: boxShadow.widget,
			backgroundColor: 'white',
			padding: '10px 16px',
			fontFamily: colorScheme.fontFamilyRegular,
			transition: noAnimationTransition ? 'initial' : 'opacity 1s ease, bottom 450ms ease',
			zIndex: 1,
			width: 'auto',
			color: colorScheme.colorVariables.colorText,
			...smallViewPort.body,
			fontSize: 17,
			...colorScheme.customStyle.tooltip?.component,
		},
		hidden: {
			marginTop: 0,
			opacity: 0,
			visibility: 'hidden',
			padding: 0,
			overflow: 'hidden',
			pointerEvents: 'none',
			height: 0,
			transition: noAnimationTransition
				? 'initial'
				: 'opacity 0.3s, padding 0.4s, height 0.2s ease 0.2s, margin 0.4s',
		},
		shown: {
			opacity: 1,
		},
		dismissButton: {
			transition: 'all 0.2s',
			borderRadius: '50%',
			// TODO: remove hardcoded colors
			backgroundColor: 'hsla(0, 0%, 20%, 0.08)',
			padding: 0,
			border: '1px solid hsla(0, 0%, 100%, 0.4)',
			width: 24,
			minWidth: 24,
			height: 24,
			'&:hover': {
				backgroundColor: 'hsla(0, 0%, 20%, 0.08)',
			},
			'& svg': {
				width: 20,
				height: 20,
			},
		},
		minimiseButton: {
			backgroundColor: colorScheme.colorVariables.colorSysBgDefault,
			border: 'none',
		},
	}),
);

export const useFadeStyles = makeStyles({
	enter: {
		opacity: 0,
	},
	enterActive: {
		opacity: 1,
		transition: 'opacity 200ms',
	},
	exit: {
		opacity: 1,
	},
	exitActive: {
		opacity: 0,
		transition: 'opacity 200ms',
	},
});
