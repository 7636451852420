import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AgentAvatarIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 48 48'} width={'48px'} height={'48px'} {...props}>
		<defs>
			<circle id={'path-1'} cx={'24'} cy={'24'} r={'24'} />
		</defs>
		<g id={'✅-Live-Chat-V1-+-Specs'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g id={'Specs-Live-Chat-·-Live-Chat-Bar'} transform={'translate(-602.000000, -500.000000)'}>
				<g id={'Group-5'} transform={'translate(586.000000, 484.000000)'}>
					<g id={'sds_liveChatAgent_default'} transform={'translate(16.000000, 16.000000)'}>
						<g id={'Background'}>
							<mask id={'mask-2'} fill={'white'}>
								<use xlinkHref={'#path-1'} />
							</mask>
							<use id={'Oval'} fill={'#EFEFF1'} xlinkHref={'#path-1'} />
						</g>
						<g id={'sds_agentAvatar_placeholder'} transform={'translate(8.000000, 7.000000)'}>
							<g id={'Group'}>
								<rect id={'Rectangle'} x={'0'} y={'0'} width={'32'} height={'32'} />
								<rect id={'Rectangle'} x={'0'} y={'0'} width={'32'} height={'32'} />
							</g>
							<g
								id={'Group'}
								transform={'translate(2.666667, 4.000000)'}
								fill={'#9799A5'}
								fillRule={'nonzero'}
							>
								<path
									d={
										'M25.3333333,12.2933333 C25.3333333,4.97333333 19.6533333,0 13.3333333,0 C7.08,0 1.33333333,4.86666667 1.33333333,12.3733333 C0.533333333,12.8266667 0,13.68 0,14.6666667 L0,17.3333333 C0,18.8 1.2,20 2.66666667,20 L2.66666667,20 C3.4,20 4,19.4 4,18.6666667 L4,12.2533333 C4,7.14666667 7.93333333,2.68 13.04,2.53333333 C18.32,2.37333333 22.6666667,6.61333333 22.6666667,11.8666667 L22.6666667,21.3333333 L13.3333333,21.3333333 C12.6,21.3333333 12,21.9333333 12,22.6666667 L12,22.6666667 C12,23.4 12.6,24 13.3333333,24 L22.6666667,24 C24.1333333,24 25.3333333,22.8 25.3333333,21.3333333 L25.3333333,19.7066667 C26.12,19.2933333 26.6666667,18.48 26.6666667,17.52 L26.6666667,14.4533333 C26.6666667,13.52 26.12,12.7066667 25.3333333,12.2933333 Z'
									}
									id={'Path'}
								/>
								<circle id={'Oval'} cx={'9.33333333'} cy={'13.3333333'} r={'1.33333333'} />
								<circle id={'Oval'} cx={'17.3333333'} cy={'13.3333333'} r={'1.33333333'} />
								<path
									d={
										'M21.3333333,10.7066667 C20.6933333,6.90666667 17.3866667,4 13.4,4 C9.36,4 5.01333333,7.34666667 5.36,12.6 C8.65333333,11.2533333 11.1333333,8.32 11.84,4.74666667 C13.5866667,8.25333333 17.1733333,10.6666667 21.3333333,10.7066667 Z'
									}
									id={'Path'}
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
);
