import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const NoImageIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 -960 960 960'} {...props}>
		<path
			d={
				'm840-234-80-80v-446H314l-80-80h526q33 0 56.5 23.5T840-760v526ZM792-56l-64-64H200q-33 0-56.5-23.5T120-200v-528l-64-64 56-56 736 736-56 56ZM240-280l120-160 90 120 33-44-283-283v447h447l-80-80H240Zm297-257ZM424-424Z'
			}
		/>
	</SvgIcon>
);
