import { TFunction } from 'i18next';

import { InputValidationResult } from '../ui-kit/input-with-validation/input-with-validation.component';

// ================ PHONE ======================
export const PHONE_NUMBER_DIGITS = 10;
export const US_INTERNATIONAL_PHONE_NUMBER_DIGITS = 11;
export const US_STARTING_FORMAT = ['+1', '(1', '1'];

export const sanitizePhoneNumber = (value: string): string => value.replace(/\D/g, '');

export const isInternationalPhoneUSFormat = (value: string): boolean =>
	US_STARTING_FORMAT.some((v) => value.startsWith(v));

export const formatPhoneNumber = (value: string): string => {
	const input = sanitizePhoneNumber(value);
	const size = input.length;

	if (isInternationalPhoneUSFormat(value) && size === 11) {
		return input.replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/g, '+$1 ($2) $3-$4');
	}
	if (size === 10) {
		return input.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3');
	}
	return value;
};
// ===================== WEIGHT ========================

export const validateWeight = (value: string, unit: string, t: TFunction): InputValidationResult => {
	const selectedUnitError = unit === 'lbs' ? '1100' : '500';
	const translatedUnit = t(`${unit}`);
	if (isNaN(Number(value)) || Number(value) < 1 || Number(value) > 1100) {
		return {
			isValid: false,
			error: t('weightInputInvalidCharsError', 'Please enter a valid weight between 1 and ', {
				unit: translatedUnit,
				value: selectedUnitError,
			}),
			displayWhileTyping: true,
		};
	}
	return {
		isValid: true,
	};
};
