import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LeaveIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 48 48'} width={'24'} height={'24'} {...props}>
		<path
			d={
				'M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h14.55v3H9v30h14.55v3Zm24.3-9.25-2.15-2.15 5.1-5.1h-17.5v-3h17.4l-5.1-5.1 2.15-2.15 8.8 8.8Z'
			}
		/>
	</SvgIcon>
);
