import { makeStyles } from '@material-ui/core';

import { MAX_WIDGET_WIDTH } from '../../utils/sizes.utils';

export const useCloseButtonStyles = makeStyles(({ palette: { sizes } }) => ({
	root: {
		padding: 0,
		color: 'white',
		width: sizes.size_6,
		height: sizes.size_5,
		'&:hover': {
			opacity: 1,
			backgroundColor: 'transparent',
		},
	},
}));

export const useModalStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
			boxShadow,
		},
	}) => ({
		root: {
			width: '100%',
			maxWidth: MAX_WIDGET_WIDTH,
			position: 'absolute',
			paddingTop: '4vh',
			margin: '0 auto',
			'& > div:first-child': {
				backgroundColor: `${colorVariables.colorMediumShadow} !important`,
			},
		},
		contentWrapper: {
			borderTopLeftRadius: 16,
			borderTopRightRadius: 16,
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			boxShadow: boxShadow.widget,
			outline: 'none',
			'&:focus-visible': {
				outline: 'none',
			},
		},
		header: {
			padding: `0px ${sizes.size_2}px`,
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			backgroundColor: 'white',
			height: sizes.size_7,
		},
	}),
);
