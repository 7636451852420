import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import classNames from 'classnames';
import { FC, Fragment } from 'react';

import { Effect } from '../../utils/function.utils';
import { Chip } from '../chip/chip.component';
import { Icon, IconProps } from '../icon/icon.component';
import { FilledCircleIcon } from '../icons/filled-circle.icon';
import { OutlinedCircleIcon } from '../icons/outlined-circle.icon';
import { useChipMultiSelectStyles } from './chip-multiselect.styles';

interface ChipMultiselectProps {
	onClick: Effect<boolean>;
	isSelected: boolean;
	isDisabled?: boolean;
	className?: string;
	label?: string;
	isFullWidth?: boolean;
	dataTestingLabel?: string;
}

export const ChipMultiselect: FC<ChipMultiselectProps> = ({
	isSelected,
	isDisabled,
	label,
	isFullWidth,
	className,
	onClick,
	dataTestingLabel,
}) => {
	const classes = useChipMultiSelectStyles();
	const chipClassName = classNames(classes.root, isFullWidth && classes.fullWidth, className);

	const checkedIcon = (
		<Fragment>
			<Icon icon={FilledCircleIcon} size={'large'} iconType={'buttonIcon'} />
			<Icon icon={CheckRoundedIcon} size={'medium'} iconType={'buttonIcon'} className={classes.checkIcon} />
		</Fragment>
	);

	const uncheckedIconProps: Pick<IconProps, 'icon' | 'iconType'> = isDisabled
		? { icon: OutlinedCircleIcon, iconType: 'chipIcon' }
		: { icon: FilledCircleIcon, iconType: 'buttonIcon' };

	const uncheckedIcon = <Icon {...uncheckedIconProps} size={'large'} className={classes.uncheckedIcon} />;

	const chipIcon = isSelected ? checkedIcon : uncheckedIcon;
	const handleClick = () => onClick(!isSelected);

	return (
		<Chip
			label={label}
			isDisabled={isDisabled}
			isSelected={isSelected}
			icon={chipIcon}
			className={chipClassName}
			onClick={handleClick}
			dataTestingLabel={dataTestingLabel}
		/>
	);
};
