import classnames from 'classnames';
import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
	CardResponse,
	ProviderAvailability,
	ProviderCard as ProviderCardType,
	ProviderReview,
} from '../../../../../models/message.model';
import { EventType } from '../../../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../../../ui-kit/button/button.component';
import { CarouselCard } from '../../../../../ui-kit/carousel/carousel-card/carousel-card.component';
import { Icon } from '../../../../../ui-kit/icon/icon.component';
import { EventAvailableIcon } from '../../../../../ui-kit/icons/event-available.icon';
import { LocationFilledIcon } from '../../../../../ui-kit/icons/location-filled.icon';
import { NewPatientIcon } from '../../../../../ui-kit/icons/new-patient.icon';
import { PersonOffIcon } from '../../../../../ui-kit/icons/person-off.icon';
import { PhoneFilledIcon } from '../../../../../ui-kit/icons/phone-filled.icon';
import { StarRatingIcon } from '../../../../../ui-kit/icons/star-rating.icon';
import { VideoVisitIcon } from '../../../../../ui-kit/icons/video-visit.icon';
import { Link } from '../../../../../ui-kit/link/link.component';
import { renderArraySeparatedWithComma } from '../../../../../utils/array.utils';
import { getDistance, IMPERIAL_DISTANCE } from '../../../../../utils/distance.utils';
import { stringToDateFormat } from '../../../../../utils/time.utils';
import { Nullable } from '../../../../../utils/types.utils';
import { useProviderCardStyles } from './provider-card.styles';

interface ProviderCardProps {
	card: ProviderCardType;
	onResponse: (response: CardResponse, e: EventType) => void;
}

type HeadingLabel = 'phone' | 'fax' | 'new_patients' | 'no_new_patients' | 'virtual_vizits' | 'next_available';

const getLocalizationKey = (distance: number, units: 'km' | 'miles'): string => {
	switch (units) {
		case 'km':
			return 'distanceKms';
		case 'miles':
			return distance === 1 ? 'oneMile' : 'distanceMiles';
	}
};

export const ProviderCard: FC<ProviderCardProps> = memo(({ card, onResponse }) => {
	const classes = useProviderCardStyles();
	const { t } = useTranslation();
	const { data, responses } = card;
	const {
		distance,
		address,
		addressPlaceholder,
		link,
		phone,
		phonePlaceholder,
		fax,
		reviews,
		languages,
		availability,
		imageUrl,
		name,
		specialties,
		acceptingVirtualVisits,
		acceptingNewPatients,
	} = data;

	const renderPersonalInfo = (name: string, image?: Nullable<string>, mainImage?: Nullable<string>): JSX.Element => {
		const providerImage = mainImage || image;
		return (
			<header>
				{providerImage && <img src={providerImage} alt={'provider'} className={classes.providerImage} />}
				<h4 className={classnames(classes.providerTitle, classes.oveflowEllipsis)}>{name}</h4>
			</header>
		);
	};

	const createRating = (reviews: ProviderReview): JSX.Element => {
		const roundedRating = reviews.ratingAverage.toFixed(1);
		return (
			<span className={classes.centeredInlineFlex}>
				<Icon
					viewBox={'0 0 20 20'}
					icon={StarRatingIcon}
					size={'small'}
					iconType={'decorIcon'}
					alt={'rating icon'}
					className={classes.ratingIcon}
				/>
				<strong className={classes.rating}>{roundedRating}</strong>
				<span>({reviews.ratingCount})</span>
			</span>
		);
	};

	const renderList = (list: string[]): JSX.Element => {
		const specialitiesString = renderArraySeparatedWithComma(list);
		return <div className={classes.oveflowEllipsis}>{specialitiesString}</div>;
	};

	const createDistance = (distance: number): JSX.Element => {
		const { language } = window.navigator;
		const distanceType = IMPERIAL_DISTANCE.includes(language) ? 'miles' : 'km';

		const distanceNumber = getDistance(distance, distanceType);
		const localizationKey = getLocalizationKey(distanceNumber, distanceType);
		const distanceString = t(localizationKey, { distance: distanceNumber });
		return <span>{distanceString}</span>;
	};

	const createActions = (responses: CardResponse[], link?: Nullable<string>, phone?: Nullable<string>) => {
		const mapLink = link && (
			<Link
				linkType={'typeLink2'}
				href={link}
				target={'_blank'}
				rel={'noreferrer'}
				variant={'button'}
				className={classes.additionalActionLink}
			>
				<Icon icon={LocationFilledIcon} size={'large'} iconType={'buttonIcon'} alt={'open map icon'} />
				<span className={classes.actionLinkLabel}>{t('map', 'Map')}</span>
			</Link>
		);

		const phoneLink = (
			<Link
				linkType={'typeLink2'}
				href={`tel:${phone}`}
				target={'_parent'}
				variant={'button'}
				className={classes.additionalActionLink}
			>
				<Icon icon={PhoneFilledIcon} size={'large'} iconType={'buttonIcon'} alt={'call icon'} />
				<span className={classes.actionLinkLabel}>{t('call', 'Call')}</span>
			</Link>
		);

		const responsesToMainActions = responses.length > 1 ? responses.slice(0, -1) : responses;

		return (
			<div className={classes.actionsRoot}>
				{responsesToMainActions.map((response, index) => (
					<Button
						key={index}
						color={'primary'}
						onClick={(e) => onResponse(response, e)}
						data-testing-label={'carousel-provider-card-main-action'}
						className={classes.actionButton}
					>
						{response.content}
					</Button>
				))}

				<div className={classes.additionlActionsWrapper}>
					{phone && phoneLink}
					{mapLink}
				</div>
			</div>
		);
	};

	const getIconByType = (type: HeadingLabel): JSX.Element => {
		switch (type) {
			case 'fax':
				return <strong>F</strong>;
			case 'phone':
				return <strong>P</strong>;
			case 'new_patients':
				return (
					<Icon
						viewBox={'0 0 20 20'}
						icon={NewPatientIcon}
						size={'small'}
						iconType={'decorIcon'}
						alt={'new patients icon'}
					/>
				);
			case 'no_new_patients':
				return (
					<Icon
						viewBox={'0 0 20 20'}
						icon={PersonOffIcon}
						size={'small'}
						iconType={'systemIconAlert'}
						alt={'no new patients icon'}
					/>
				);
			case 'virtual_vizits':
				return (
					<Icon
						viewBox={'0 0 20 20'}
						icon={VideoVisitIcon}
						size={'small'}
						iconType={'decorIcon'}
						alt={'virtual visit icon'}
					/>
				);
			case 'next_available':
				return (
					<Icon
						viewBox={'0 0 20 20'}
						icon={EventAvailableIcon}
						size={'small'}
						iconType={'decorIcon'}
						alt={'virtual visit icon'}
					/>
				);
		}
	};

	const createNewPatients = (acceptingNewPatients: boolean) => {
		const iconType = acceptingNewPatients ? 'new_patients' : 'no_new_patients';
		const icon = getIconByType(iconType);
		return (
			<span
				className={classnames(classes.additionalInfoRoot, classes.centeredInlineFlex)}
				id={'provider-new-patients-label'}
			>
				{icon}
				New Patients
			</span>
		);
	};

	const createNextAvailable = (availability: ProviderAvailability) => {
		const icon = getIconByType('next_available');
		const label = availability.nextDate
			? stringToDateFormat(availability.nextDate)
			: t('availableWithin', { days: availability.availableInNextDays });
		return (
			<span
				className={classnames(classes.additionalInfoRoot, classes.centeredInlineFlex)}
				id={'provider-next-available-label'}
			>
				{icon}
				<span>{label}</span>
			</span>
		);
	};

	const createVirtualVisit = () => {
		const icon = getIconByType('virtual_vizits');
		return (
			<span
				className={classnames(classes.additionalInfoRoot, classes.centeredInlineFlex)}
				id={'provider-virtual-visit-label'}
			>
				{icon}
				Virtual Visits
			</span>
		);
	};

	const renderRatingAndLanguages = (languages: string[], reviews?: Nullable<ProviderReview>) => (
		<div className={classnames(classes.ratingRoot, classes.withSeparator)}>
			{reviews && createRating(reviews)} {renderList(languages)}
		</div>
	);

	const renderDistanceAndAddress = (
		addressPlaceholder: string,
		distance?: Nullable<number>,
		address?: Nullable<string>,
	) => (
		<div className={classes.withSeparator}>
			{distance && createDistance(distance)}
			<span>{address ? address : addressPlaceholder}</span>
		</div>
	);

	const renderPhoneAndFax = (phonePlaceholder: string, phone?: Nullable<string>, fax?: Nullable<string>) => {
		const phoneLabel = (
			<span>
				{phone ? (
					<Fragment>
						{getIconByType('phone')}
						<span> {phone}</span>
					</Fragment>
				) : (
					phonePlaceholder
				)}
			</span>
		);

		const faxLabel = fax && (
			<span>
				{getIconByType('fax')}
				<span> {fax}</span>
			</span>
		);

		return (
			<div className={classes.withSeparator}>
				{phoneLabel}
				{faxLabel}
			</div>
		);
	};

	const renderAdditionalInfo = (
		acceptingNewPatients: Nullable<boolean>,
		acceptingVirtualVisits: Nullable<boolean>,
		availability?: Nullable<ProviderAvailability>,
	) => {
		const newPatientsLabel = acceptingNewPatients === null ? null : createNewPatients(acceptingNewPatients);
		const virtualVisitsLabel = acceptingVirtualVisits ? createVirtualVisit() : null;
		const nextAvailableLabel = availability ? createNextAvailable(availability) : null;
		return (
			<Fragment>
				{newPatientsLabel} {virtualVisitsLabel} {nextAvailableLabel}
			</Fragment>
		);
	};

	return (
		<CarouselCard>
			<div className={classes.root}>
				<div className={classes.section}>
					{renderPersonalInfo(name, card.imageUrl, imageUrl)}
					{renderList(specialties)}
					{renderRatingAndLanguages(languages, reviews)}
				</div>

				<div className={classes.section}>{renderDistanceAndAddress(addressPlaceholder, distance, address)}</div>
				<div className={classes.section}>{renderPhoneAndFax(phonePlaceholder, phone, fax)}</div>

				<div className={classes.section}>
					{renderAdditionalInfo(acceptingNewPatients, acceptingVirtualVisits, availability)}
				</div>
			</div>
			{createActions(responses, link, phone)}
		</CarouselCard>
	);
});
