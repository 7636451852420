import { makeStyles } from '@material-ui/core';

export const useLLMFeedbackStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
			sizes,
		},
		typography: { smallViewPort },
	}) => ({
		wrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'column',
		},
		root: {
			display: 'flex',
			flexDirection: 'column',
			padding: sizes.size_3,
			border: `1px solid ${colorVariables.colorLightShadow}`,
			borderRadius: borderRadius.card,
		},
		header: {
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'space-between',
			width: '100%',
		},
		title: {
			...smallViewPort.typeHeading2,
			margin: 0,
		},
		closeIcon: {
			padding: 0,
		},
		optionsRoot: {
			display: 'flex',
			flexWrap: 'wrap',
			paddingTop: sizes.size_2,
			gap: sizes.sizeHalf,
			justifyContent: 'center',
		},
		sumbitButton: {
			margin: '0 auto',
		},
	}),
);

export const useTextFieldStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
			sizes,
		},
	}) => ({
		root: {
			marginTop: sizes.size_2,
			width: '100%',
			height: 168,
			border: `1px solid ${colorVariables.colorBorder}`,
			borderRadius: borderRadius.button,
			marginBottom: sizes.size_3,
			'& > div': {
				padding: sizes.size_2,
				width: '100%',
				height: '100%',
				boxShadow: 'none',
				alignItems: 'flex-start',
				border: '1px solid transparent',
			},
			'& textarea:first-child': {
				width: '100%',
				maxHeight: '100%',
				'&::placeholder': {
					color: colorVariables.colorNote,
				},
			},
		},
	}),
);
