import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CancelIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} width={'20'} height={'20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon opacity={'0.87'} points={'0 0 20 0 20 20 0 20'}></polygon>
				<path
					d={
						'M10,1.66666667 C5.39166667,1.66666667 1.66666667,5.39166667 1.66666667,10 C1.66666667,14.6083333 5.39166667,18.3333333 10,18.3333333 C14.6083333,18.3333333 18.3333333,14.6083333 18.3333333,10 C18.3333333,5.39166667 14.6083333,1.66666667 10,1.66666667 Z M13.5833333,13.5833333 C13.2583333,13.9083333 12.7333333,13.9083333 12.4083333,13.5833333 L10,11.175 L7.59166667,13.5833333 C7.26666667,13.9083333 6.74166667,13.9083333 6.41666667,13.5833333 C6.09166667,13.2583333 6.09166667,12.7333333 6.41666667,12.4083333 L8.825,10 L6.41666667,7.59166667 C6.09166667,7.26666667 6.09166667,6.74166667 6.41666667,6.41666667 C6.74166667,6.09166667 7.26666667,6.09166667 7.59166667,6.41666667 L10,8.825 L12.4083333,6.41666667 C12.7333333,6.09166667 13.2583333,6.09166667 13.5833333,6.41666667 C13.9083333,6.74166667 13.9083333,7.26666667 13.5833333,7.59166667 L11.175,10 L13.5833333,12.4083333 C13.9,12.725 13.9,13.2583333 13.5833333,13.5833333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
