import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useCarouselRendererStyles = makeStyles<Theme>(({ palette: { sizes } }) => ({
	root: {
		marginTop: 5,
	},
	locationRoot: {
		padding: `0 ${sizes.size_1}%`,
	},
	itemClass: {
		margin: '0 5px',
	},
	carouselContainer: {
		'& > ul': {
			width: '100%',
		},
	},
	secondaryActionRoot: {
		padding: `${sizes.size_2}px 0`,
		textAlign: 'center',
	},
	noSecondaryButton: {
		paddingBottom: sizes.size_2,
	},
	stackedButtonsContainer: {
		paddingLeft: 0,
		paddingRight: 0,
		'& > div': {
			width: '100%',
		},
	},
}));
