import { FC } from 'react';

import { Icon, IconType } from '../icon/icon.component';
import { LoadingIcon } from '../icons/loading.icon';
import { useLoadingStyles } from './loading-indicator.styles';

interface LoadingIndicatorProps {
	size?: 'large' | 'medium';
	color?: IconType;
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
	size = 'medium',
	color = 'inputIcon',
}: LoadingIndicatorProps) => {
	const classes = useLoadingStyles();
	return (
		<Icon
			dataTestingLabel={'loading-indicator'}
			icon={LoadingIcon}
			viewBox={'0 0 32 32'}
			size={size}
			iconType={color}
			alt={'widget-loading icon'}
			className={classes.loader}
		/>
	);
};
