import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const EventAvailableIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon id={'Path'} points={'0 0 20 0 20 20 0 20'}></polygon>
				<path
					d={
						'M13.3333333,8.775 C13.0916667,8.53333333 12.6916667,8.53333333 12.45,8.775 L8.825,12.4 L7.5,11.075 C7.25833333,10.8333333 6.85833333,10.8333333 6.61666667,11.075 C6.375,11.3166667 6.375,11.7166667 6.61666667,11.9583333 L8.23333333,13.575 C8.55833333,13.9 9.08333333,13.9 9.40833333,13.575 L13.325,9.65833333 C13.575,9.41666667 13.575,9.01666667 13.3333333,8.775 Z M15.8333333,2.5 L15,2.5 L15,1.66666667 C15,1.20833333 14.625,0.833333333 14.1666667,0.833333333 C13.7083333,0.833333333 13.3333333,1.20833333 13.3333333,1.66666667 L13.3333333,2.5 L6.66666667,2.5 L6.66666667,1.66666667 C6.66666667,1.20833333 6.29166667,0.833333333 5.83333333,0.833333333 C5.375,0.833333333 5,1.20833333 5,1.66666667 L5,2.5 L4.16666667,2.5 C3.24166667,2.5 2.50833333,3.25 2.50833333,4.16666667 L2.5,15.8333333 C2.5,16.75 3.24166667,17.5 4.16666667,17.5 L15.8333333,17.5 C16.75,17.5 17.5,16.75 17.5,15.8333333 L17.5,4.16666667 C17.5,3.25 16.75,2.5 15.8333333,2.5 Z M15,15.8333333 L5,15.8333333 C4.54166667,15.8333333 4.16666667,15.4583333 4.16666667,15 L4.16666667,6.66666667 L15.8333333,6.66666667 L15.8333333,15 C15.8333333,15.4583333 15.4583333,15.8333333 15,15.8333333 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
