import { WebTrackerAction, WebTrackerEvent } from '../../models/trackers.model';
import { BasicEventConfig, TRACKER_CHAT_EVENTS } from './web-tracker-service.model';

interface PostToParentEventConfig extends BasicEventConfig {
	logEvent: WebTrackerEvent;
}

export const getPostToParentEventType = (type: WebTrackerAction): string => {
	const eventCategory = TRACKER_CHAT_EVENTS.includes(type) ? 'chat' : 'ui';

	return `${eventCategory}.${type}`;
};

export const sendPostToParentEvent = (eventConfig: PostToParentEventConfig): void => {
	const { logEvent, action, label, category, logEventsToConsole } = eventConfig;
	// check if received data is direct or will be obtained by event
	const eventType = getPostToParentEventType(logEvent.type);

	const eventDetail = {
		type: eventType,
		action,
		label,
		category,
		data: null, // TODO: check if tags of an element are still needed
	};
	const customEvent = new CustomEvent('GYANTEvents', { detail: eventDetail });
	window.dispatchEvent(customEvent);

	if (logEventsToConsole) {
		console.log('GyantTrackerPostToParentHandler: ', eventDetail);
	}
};
